import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader.jsx';
import Message from '../../components/Message.jsx';
import { useGetUsersManagerQuery } from '../../slices/usersApiSlice.js';
import {
  useGetUserPrivilegeAssignmentsQuery,
  useCreatePrivilegeAssignmentMutation,
  useUpdatePrivilegeAssignmentMutation,
  useGetPrivilegesQuery,
  useDeletePrivilegeAssignmentMutation,
} from '../../slices/privilegeApiSlice.js';

const UserPrivilegeViewScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedPrivileges, setSelectedPrivileges] = useState({});
  const [userAssignmentsState, setUserAssignmentsState] = useState([]); // Local state to track assignments
  const [changedUser, setChangedUser] = useState(false);

  const {
    data: users,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useGetUsersManagerQuery(userInfo._id);

  const {
    data: userAssignments,
    isLoading: loadingAssignments,
    error: errorAssignments,
    refetch: refetchAssignments,
  } = useGetUserPrivilegeAssignmentsQuery(selectedUser?._id, {
    skip: !selectedUser,
  });

  const {
    data: privileges,
    isLoading,
    error,
    refetch,
  } = useGetPrivilegesQuery();

  const [createAssignment] = useCreatePrivilegeAssignmentMutation();
  const [updateAssignment] = useUpdatePrivilegeAssignmentMutation();
  const [deleteAssignment] = useDeletePrivilegeAssignmentMutation();

  // Handle user selection
  /* const handleUserSelection = (user) => {
    setSelectedUser(user);
    setSelectedSpeciality(null);
    setSelectedPrivileges({}); // Clear selected privileges on new user selection
  };
*/

  const handleUserSelection = (user) => {
    // Clear userAssignments and selected states immediately on new user selection
    setSelectedUser(user);
    setSelectedSpeciality(null);
    setSelectedPrivileges({});
    setUserAssignmentsState([]); // Clear the previous user's assignments immediately
    setChangedUser(true);
    // No need to manually refetch, the query will auto-fetch based on selectedUser
    setSelectedPrivileges({});
  };

  // Handle speciality selection
  const handleSpecialitySelection = (speciality) => {
    setSelectedSpeciality(speciality);
    setSelectedPrivileges({}); // Clear selected privileges on new speciality selection
  };

  // Handle checkbox toggle for privileges
  /*
  const handleTogglePrivilege = (privilegeItemId) => {
    setSelectedPrivileges((prevState) => ({
      ...prevState,
      [privilegeItemId]: !prevState[privilegeItemId],
    }));
  };
  */

  const handleTogglePrivilege = (
    privilegeItemId,
    privilegeName,
    description,
    userId,
    userName,
    specialityId,
    specialityName
  ) => {
    setSelectedPrivileges((prevState) => {
      const newState = {
        ...prevState,
        [privilegeItemId]: !prevState[privilegeItemId], // Toggle the privilege state
      };

      // Check if the selected privilege is now `true` (turned on)
      if (newState[privilegeItemId]) {
        // Perform actions when the privilege is turned on
        //console.log(`Privilege ${privilegeItemId} is now turned on.`);
        // Add any additional logic here, such as API calls, state updates, etc.

        handleUpdateOnClick(
          privilegeItemId,
          privilegeName,
          description,
          userId,
          userName,
          specialityId,
          specialityName
        );

        //***********************************************************************************************
      }

      return newState; // Update the state
    });
  };

  // Handle update on click
  const handleUpdateOnClick = async (
    privilegeItemId,
    privilegeName,
    description,
    userId,
    userName,
    specialityId,
    specialityName
  ) => {
    try {
      await updateAssignment({
        selectedUserId: selectedUser._id,
        updatedData: {
          privilegeItemId,
          privilegeName,
          description,
          userId,
          userName,
          specialityId,
          specialityName,
        },
      });

      // Refetch the userAssignments to ensure the updated privileges are displayed
      refetchAssignments();
      toast.success('User privileges updated successfully');
    } catch (error) {
      //console.error('Failed to update privilege', error);
      toast.error('Failed to update privileges');
    }
  };

  //______________________________________________________________________________
  // Handle udelete on click
  const handleDeleteOnClick = async (assignmentId) => {
    //console.log('handleDeleteOnClick :assignmentId', assignmentId);

    try {
      await deleteAssignment(assignmentId);

      // Refetch the userAssignments to ensure the updated privileges are displayed
      refetchAssignments();
      toast.success('User privileges removed successfully');
    } catch (error) {
      //console.error('Failed to remove privilege', error);
      toast.error('Failed to remove privileges');
    }
  };

  // Synchronize "Already Assigned Privileges" with "Privilege Items"
  /* useEffect(() => {
    if (userAssignments?.length > 0) {
      const updatedPrivileges = {};
      userAssignments.forEach((assignedPrivilege) => {
        updatedPrivileges[assignedPrivilege._id] = true; // Mark assigned privileges as true
      });
      setSelectedPrivileges(updatedPrivileges);
    }
  }, [userAssignments]);
*/

  /*

  useEffect(() => {
    if (selectedUser) {
      // Clear the state immediately when a new user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});

      // Refetch assignments for the new user
      refetchAssignments({ force: true });

      if (!loadingAssignments) {
        // Check if there are assignments for the new user after loading completes
        if (userAssignments && userAssignments.length > 0) {
          const updatedPrivileges = {};
          userAssignments.forEach((assignedPrivilege) => {
            updatedPrivileges[assignedPrivilege._id] = true; // Mark assigned privileges as true
          });
          setSelectedPrivileges(updatedPrivileges);
          setUserAssignmentsState(userAssignments); // Update state with fetched assignments
        } else {
          // Clear the state if no assignments exist for the selected user
          setUserAssignmentsState([]); // Explicitly set an empty array
          setSelectedPrivileges({}); // Clear selected privileges
        }
      }
    } else {
      // Clear state if no user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});
    }
  }, [
    selectedUser,
    loadingAssignments,
    userAssignments,
    refetchAssignments,
    changedUser,
  ]);

  */

  useEffect(() => {
    if (selectedUser) {
      // Clear state immediately when a new user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});

      // Trigger the refetch for assignments
      refetchAssignments({ force: true });
    } else {
      // Clear state if no user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});
    }
  }, [selectedUser, refetchAssignments]);

  useEffect(() => {
    // Wait for loading to complete and assignments to be available
    if (!loadingAssignments && !errorAssignments && selectedUser) {
      if (userAssignments && userAssignments.length > 0) {
        const updatedPrivileges = {};
        userAssignments.forEach((assignedPrivilege) => {
          updatedPrivileges[assignedPrivilege._id] = true; // Mark assigned privileges as true
        });
        setSelectedPrivileges(updatedPrivileges);
        setUserAssignmentsState(userAssignments); // Update state with fetched assignments
      } else {
        // Clear state if no assignments exist for the selected user
        setUserAssignmentsState([]);
        setSelectedPrivileges({});
      }
    }
  }, [loadingAssignments, errorAssignments, userAssignments, selectedUser]);

  //_______________________________________________________________________________________________________________

  // Handle form submission to create or update privilege assignment
  const handleSubmit = async () => {
    const selectedPrivilegeIds = Object.keys(selectedPrivileges).filter(
      (key) => selectedPrivileges[key]
    );

    if (selectedPrivilegeIds.length > 0) {
      try {
        await updateAssignment({
          selectedUserId: selectedUser._id,
          updatedData: { privileges: selectedPrivilegeIds },
        });
        toast.success('User privileges updated successfully');
      } catch (error) {
        toast.error('Failed to update privileges');
      }
    } else {
      toast.error('Please select at least one privilege');
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <h3>Assigned Privileges</h3>
          <h3>Select a User</h3>
          {loadingUsers ? (
            <Loader />
          ) : errorUsers ? (
            <Message variant='danger'>
              {errorUsers?.data?.message || errorUsers.error}
            </Message>
          ) : (
            <Form.Control
              as='select'
              onChange={(e) =>
                handleUserSelection(users.find((u) => u._id === e.target.value))
              }
            >
              <option value=''>-- Select a User --</option>
              {users.map((user) => {
                const fixedName = (user.name || 'No Name')
                  .slice(0, 20)
                  .padEnd(20, ' '); // Adjust '20' as the desired fixed length for name
                const fixedSpeciality = (user.speciality || 'No Speciality')
                  .slice(0, 20)
                  .padEnd(20, ' '); // Adjust '20' as the desired fixed length for speciality

                return (
                  <option key={user._id} value={user._id}>
                    {`${fixedName} | ${fixedSpeciality}`}
                  </option>
                );
              })}
            </Form.Control>
          )}
        </Col>
      </Row>

      <Col md={12}>
        {loadingAssignments ? (
          <Loader />
        ) : errorAssignments ? (
          <Message variant='danger'>
            {errorAssignments?.data?.message || errorAssignments.error}
          </Message>
        ) : (
          selectedUser && (
            <Row className='mt-4'>
              <h3>Assigned Privileges</h3>

              <Card>
                <Card.Body>
                  {userAssignmentsState?.length > 0 ? (
                    <Form
                      style={{
                        backgroundColor: '#fef9e7',
                        padding: '10px',
                        borderRadius: '5px',
                      }}
                    >
                      {userAssignmentsState.map((assignedPrivilege) => (
                        <Form.Check
                          key={assignedPrivilege._id}
                          type='checkbox'
                          checked={
                            selectedPrivileges[assignedPrivilege._id] || false
                          }
                          label={
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '200px',
                                  padding: '5px',
                                  backgroundColor: '#f0f0f0',
                                }}
                              >
                                {assignedPrivilege.specialityName}
                              </div>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '300px',
                                  padding: '5px',
                                }}
                              >
                                {assignedPrivilege.privilegeName}
                              </div>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '600px',
                                  padding: '5px',
                                }}
                              >
                                {assignedPrivilege.description}
                              </div>
                            </div>
                          }
                        />
                      ))}
                    </Form>
                  ) : (
                    <Message>No privileges assigned yet</Message>
                  )}
                </Card.Body>
              </Card>
            </Row>
          )
        )}
      </Col>
    </>
  );
};

export default UserPrivilegeViewScreen;
