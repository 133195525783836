import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Modal, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FaEdit, FaPlus, FaTrash, FaTimes, FaCheck } from 'react-icons/fa';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';

import {
  useGetMyWorklistsQuery,
  useCreateWorklistMutation,
} from '../slices/worklistsApiSlice';
//import { setCredentials } from '../slices/authSlice';
import { Link } from 'react-router-dom';
import MyClinicalSCommitments from '../components/MyClinicalCommitments';
import MyProfile from '../components/MyProfile';
import { calcTotal } from '../utils/calcTotals.js';
import '../utils/tableStack.css';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';
//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const WorklistScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  //const { pageNumber } = useParams();
  const { myUserId } = userInfo._id;

  //console.log('userInfo._id', userInfo._id);

  const {
    data: worklists,
    isLoading,
    error,
    refetch,
  } = useGetMyWorklistsQuery();

  const dispatch = useDispatch();

  const [createWorklist, { isLoading: loadingCreate }] =
    useCreateWorklistMutation();

  const createWorklistHandler = async () => {
    if (window.confirm('Are you sure you want to create a new Worklist?')) {
      try {
        await createWorklist();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //______________________________________________
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (worklists) {
      refetch();

      //console.log('worklists', worklists);

      const formattedRows = worklists
        .slice()

        .sort((a, b) => {
          // Compare by createdAt
          const dayComparison = a.createdAt.localeCompare(b.createdAt);

          if (dayComparison !== 0) {
            return dayComparison;
          }

          return 0; // Return 0 if dates are the same
        })

        .map((item) => ({
          id: item._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string
          worklistName: item.worklistName, // Access the nested user object
          description: item.description,
          isManagerApproved: item.isManagerApproved,
          isAdminApproved: item.isAdminApproved,
          createdAt: new Date(item.createdAt).toLocaleDateString('en-GB'), // Format date to dd/mm/yyyy,
        }));

      setLoading(false);

      setRows(formattedRows);
      //get window size
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [worklists]);

  const navigate = useNavigate();

  const handleEdit = (id) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/worklist/${id}/edit`);
  };

  const handleDelete = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    // Add your delete handling logic here
  };

  const handleEditActivities = (id) => {
    //console.log(`Delete item with ID: ${id}`);
    navigate(`/worklist/${id}`);

    // Add your delete handling logic here
  };

  // className='table-sm' normal table class ,
  //<td style={{ backgroundColor: 'Moccasin', width: '10%' }}>   edit activities btton inline style

  const columns = [
    //{ field: 'id', headerName: 'ID', hide: true, width: 90 },
    // { field: 'user', headerName: 'User', hide: true, width: 150 },
    { field: 'createdAt', headerName: 'Created', width: 100 },
    {
      field: 'worklistName',
      headerName: 'Worklist Name',
      width: 200,
      hide: true,
    },

    // { field: 'UKey', headerName: 'UKey', width: 150, hide: true },
    { field: 'description', headerName: 'Description', width: 350 },

    //{ field: 'day', headerName: 'Day', width: 100 },
    //{ field: 'period', headerName: 'Period', width: 100 },

    {
      field: 'isManagerApproved',
      headerName: 'Manager Approved',
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },
    {
      field: 'isAdminApproved',
      headerName: 'Admin Approved',
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(params.id)}
          >
            <FaEdit /> Edit
          </Button>
          <Button
            size='small'
            variant='contained'
            color='warning'
            onClick={() => handleEditActivities(params.id)}
          >
            <FaEdit style={{ color: 'brown' }} /> Edit Activities
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns],
  ); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //
  // Go Back - the windowWidth is {windowWidth}
  //------------------------------------------------------------------------------

  return (
    <>
      <Link to='/' className='btn btn-light mb-4'>
        Go Back
      </Link>

      <Row>
        <Row>
          <Col>
            <h2>My Worklists</h2>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <MyProfile />
          </Col>

          <Col md={4}></Col>

          <Col md={4} className='text-end'>
            <MyClinicalSCommitments />
          </Col>
        </Row>

        {windowWidth < 900 ? (
          isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <div>
              {worklists.length === 0 ? (
                <Col md={12} className='text-end'>
                  <Button className='my-3' onClick={createWorklistHandler}>
                    <FaPlus /> Create Worklist
                  </Button>
                </Col>
              ) : (
                <Col md={12} className='text-end'>
                  <th> </th>
                </Col>
              )}
              {worklists
                .slice()

                .sort((a, b) => {
                  // Compare by createdAt
                  const dayComparison = a.createdAt.localeCompare(b.createdAt);

                  if (dayComparison !== 0) {
                    return dayComparison;
                  }

                  return 0; // Return 0 if dates are the same
                })
                .map((item) => (
                  <Card
                    key={item._id}
                    className={`mb-3 ${
                      item.worklistName.startsWith('z')
                        ? //||
                          //item.day.startsWith('0') ||
                          //item.period.startsWith('For Noting')
                          'highlightRow'
                        : ''
                    }`}
                  >
                    <Card.Body>
                      <Card.Text>
                        <strong>Worklist Name:</strong> {item.worklistName}
                      </Card.Text>
                      <Card.Text>
                        <strong>Description:</strong> {item.description}
                      </Card.Text>
                      <Card.Text>
                        <strong>CreatedAt:</strong> {item.createdAt}
                      </Card.Text>

                      <Card.Text>
                        <strong>Manager Approved:</strong>{' '}
                        {item.isManagerApproved ? (
                          <FaCheck style={{ color: 'green' }} />
                        ) : (
                          <FaTimes style={{ color: 'red' }} />
                        )}
                      </Card.Text>

                      <Card.Text>
                        <strong>Admin Approved:</strong>{' '}
                        {item.isAdminApproved ? (
                          <FaCheck style={{ color: 'green' }} />
                        ) : (
                          <FaTimes style={{ color: 'red' }} />
                        )}
                      </Card.Text>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LinkContainer
                          //to={`/manager/worklist/${worklist._id}/activity/${item.UKey}/${userid}`}
                          to={`/worklist/${item._id}/edit`}
                        >
                          <Button
                            size='sm'
                            variant='outline-info'
                            className='btn-sm mx-2'
                          >
                            <FaEdit /> Edit Item
                          </Button>
                        </LinkContainer>

                        <LinkContainer to={`/worklist/${item._id}`}>
                          <Button
                            size='sm'
                            variant='warning'
                            className='btn-sm'
                          >
                            <FaTrash
                              className='text-end'
                              style={{ color: 'white' }}
                            />{' '}
                            Edit Activities
                          </Button>
                        </LinkContainer>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              <Card className='mt-3'>
                <Card.Body>
                  <Card.Text style={{ backgroundColor: 'lightyellow' }}>
                    <strong>Total Activities: </strong>{' '}
                    {/*calcTotal(worklist.worklistItems)*/}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          )
        ) : (
          <Col md={12} className='d-flex  justify-content-between'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <Box sx={{ height: 300, width: '100%' }}>
                <strong>Total Activities : </strong>
                {worklists.length === 0 ? (
                  <Col md={12} className='text-end'>
                    <Button className='my-3' onClick={createWorklistHandler}>
                      <FaPlus /> Create Worklist
                    </Button>
                  </Col>
                ) : (
                  <Col md={12} className='text-end'>
                    <th> </th>
                  </Col>
                )}

                {/*calcTotal(worklist.worklistItems)*/}
                <StyledDataGrid
                  density='compact'
                  rows={rows}
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={loading}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                  }}
                  pageSizeOptions={[50]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  getRowClassName={(params) => {
                    if (params.row.worklistName.startsWith('Lunch Break')) {
                      return 'lightGreenRow';
                    } else if (
                      params.row.worklistName.startsWith('For Noting')
                    ) {
                      return 'orangeRow';
                    } else if (params.row.worklistName.startsWith('z')) {
                      return 'redRow';
                    }
                    return '';
                  }}
                  getRowSpacing={getRowSpacing}
                />
              </Box>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default WorklistScreen;
