import { Chart as ChartJS } from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useGetMyAggregateHeatmapDataQuery } from '../slices/worklistsApiSlice.js';
import { MultiSelect } from 'react-multi-select-component';
import '../utils/tableStack.css';

ChartJS.register(MatrixController, MatrixElement, zoomPlugin);

function MatrixChart() {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { userInfo } = useSelector((state) => state.auth);

  const [selectedClinicalSites, setSelectedClinicalSites] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);

  const {
    data: myAggregateWorkDataHeatMap = [],
    refetch,
    isLoading,
    error,
  } = useGetMyAggregateHeatmapDataQuery(userInfo._id);

  const createMatrixData = (data) =>
    data.map((item) => ({
      x: item.hour,
      y: item.day,
      v: item.userCount,
    }));

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const filteredData = myAggregateWorkDataHeatMap.filter(
      (item) =>
        (selectedClinicalSites.length > 0
          ? selectedClinicalSites.some(
              (site) => site.value === item.clinicalSite
            )
          : true) &&
        (selectedSpecialities.length > 0
          ? selectedSpecialities.some(
              (speciality) => speciality.value === item.speciality
            )
          : true)
    );

    const fallbackData = [
      { hour: 9, day: 1, userCount: 5 },
      { hour: 10, day: 2, userCount: 3 },
    ];

    // Define `matrixData` outside the conditional block
    const matrixData =
      filteredData.length === 0
        ? filteredData.map((item) => ({
            x: item.hour,
            y: item.day,
            v: item.userCount,
          }))
        : fallbackData.map((item) => ({
            x: item.hour,
            y: item.day,
            v: item.userCount,
          }));

    //console.log('matrixData..........', matrixData);

    // If the chart instance already exists, update its data
    if (chartInstanceRef.current) {
      const chartInstance = chartInstanceRef.current;
      chartInstance.data.datasets[0].data = matrixData;
      chartInstance.update();
      return;
    }

    // Create a new chart instance if it doesn't exist
    if (chartRef.current) {
      const chartInstance = new ChartJS(chartRef.current, {
        type: 'matrix',
        data: {
          datasets: [
            {
              label: 'Basic matrix',
              //data: matrixData,
              data: [
                { x: 1, y: 1 },
                { x: 2, y: 1 },
                { x: 1, y: 2 },
                { x: 2, y: 2 },
              ],

              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
              backgroundColor: (ctx) => {
                const value = ctx.raw?.v || 0;
                const alpha = Math.min(1, value / 10);
                return `rgba(98, 198, 0, ${alpha})`;
              },

              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.5)',
              backgroundColor: 'rgba(200,200,0,0.3)',
              width: ({ chart }) => (chart.chartArea || {}).width / 2 - 1,
              height: ({ chart }) => (chart.chartArea || {}).height / 2 - 1,

              /*
              width: ({ chart }) => {
                const chartArea = chart.chartArea;
                if (!chartArea) return 20; // Fallback width
                return chartArea.width / 24;
              },
              height: ({ chart }) => {
                const chartArea = chart.chartArea;
                if (!chartArea) return 20; // Fallback height
                return chartArea.height / 7;
              },
              */
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
              min: 0.5,
              max: 2.5,
              offset: false,
            },
            y: {
              display: false,
              min: 0.5,
              max: 2.5,
            },
          },
        },
      });

      chartInstanceRef.current = chartInstance;
    }
  }, [myAggregateWorkDataHeatMap, selectedClinicalSites, selectedSpecialities]);

  if (isLoading) {
    return <p>Loading data...</p>;
  }
  if (error) {
    return <p>Error loading data: {error.message || 'An error occurred.'}</p>;
  }
  if (myAggregateWorkDataHeatMap.length === 0) {
    return <p>No data available for the selected criteria.</p>;
  }

  return (
    <div>
      <h3>Matrix Chart - Heatmap of Staff Volume</h3>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div>
          <label htmlFor='clinicalSite'>Clinical Site: </label>
          <MultiSelect
            options={[
              ...new Set(
                myAggregateWorkDataHeatMap.map((item) => ({
                  label: item.clinicalSite,
                  value: item.clinicalSite,
                }))
              ),
            ]}
            value={selectedClinicalSites}
            onChange={setSelectedClinicalSites}
            labelledBy='Select Clinical Sites'
          />
        </div>
        <div>
          <label htmlFor='speciality'>Speciality: </label>
          <MultiSelect
            options={[
              ...new Set(
                myAggregateWorkDataHeatMap.map((item) => ({
                  label: item.speciality,
                  value: item.speciality,
                }))
              ),
            ]}
            value={selectedSpecialities}
            onChange={setSelectedSpecialities}
            labelledBy='Select Specialities'
          />
        </div>
      </div>
      <canvas ref={chartRef} />
      <Button
        variant='outline-primary'
        size='sm'
        onClick={() => chartInstanceRef.current.resetZoom()}
      >
        Reset Zoom
      </Button>
    </div>
  );
}

export default MatrixChart;

/*

      data: [
                { x: 1, y: 1, v: 100 },
                { x: 2, y: 1, v: 150 },
                { x: 1, y: 2, v: 50 },
                { x: 2, y: 2, v: 70 },
              ],
              
      const chartInstance = new ChartJS(chartRef.current, {
        type: 'matrix',
        data: {
          datasets: [
            {
              label: 'Heatmap of Staff Volume',
              data: [{ x: 0, y: 0, v: 0 }], // Placeholder data
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.1)',
              backgroundColor: (ctx) => {
                const value = ctx.raw?.v || 0;
                const alpha = Math.min(1, value / 10);
                return `rgba(0, 128, 0, ${alpha})`;
              },
              width: (ctx) => {
                const chartArea = ctx.chart.chartArea;
                if (!chartArea) return 20; // Fallback width
                return chartArea.width / 24; // Adjust for 24 hours
              },
              height: (ctx) => {
                const chartArea = ctx.chart.chartArea;
                if (!chartArea) return 20; // Fallback height
                return chartArea.height / 7; // Adjust for 7 days
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
            zoom: {
              pan: { enabled: true, mode: 'xy' },
              zoom: { wheel: { enabled: true }, mode: 'xy' },
            },
          },
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              title: { display: true, text: 'Hour of the Day' },
            },
            y: {
              type: 'linear',
              title: { display: true, text: 'Day of the Week' },
            },
          },
        },
      });

      
       */
