import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  FaEdit,
  FaPlus,
  FaTrash,
  FaTimes,
  FaCheck,
  FaLayerGroup,
  FaCalendarWeek,
} from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Message from '../../components/Message.jsx';
import Loader from '../../components/Loader.jsx';

import {
  useGetMyWorklistsQuery,
  useCreateWorklistMutation,
  useGetWorklistDetailsQuery,
  useCreateActivityMutation,
  useApproveAllActivityMutation,
  useDeleteWorklistActivityMutation,
  useGetAggregateWorkDataActivityQuery,
  useGetAggregateWorkDataQuery,
} from '../../slices/worklistsApiSlice.js';
//import { setCredentials } from '../../slices/authSlice.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import '../../../src/components/TimePicker.css';
import MyClinicalSCommitments from '../../components/MyClinicalCommitments.jsx';
import MyProfile from '../../components/MyProfile.jsx';
import { calcTotal } from '../../utils/calcTotals.js';

import ModalScreen from '../../components/Modal.jsx';
import WorkVolumeChart from '../../components/chartModal_test.jsx';
//import WorkVolumeChart2 from '../../components/chartModal_prod.jsx';
import WorkVolumeChart2 from '../../components/chartModal_ByDay.jsx';
import WorkVolumeChartTreeMap from '../../components/chartModal_TreeMap_prod.jsx';
//import WorkVolumeChartTreeMap from '../../components/chartModal_TreeMap_Test.jsx'; //chartModal_TreeMap_Test

import '../../utils/tableStack.css';

import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColumnMenuHideItem,
  GridToolbar,
  gridClasses,
} from '@mui/x-data-grid';
import {
  grey,
  yellow,
  common,
  orange,
  lightGreen,
  red,
} from '@mui/material/colors';
import { darken, lighten, styled } from '@mui/material/styles';
import { width } from '@mui/system';
//import { hidden } from 'colors/index.js';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const WorklistEditScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const { theName } = location.state || {};

  // Debugging log to see the state
  //console.log('Location state:', location.state);

  //console.log('manager WorklistEditScreen name', theName);

  //const { data: worklists, isLoading, error } = useGetMyWorklistsQuery();

  const { id: worklistId } = useParams();
  const { userID: userid } = useParams();

  //console.log('in WorklistEditScreen  , the user is ', userid);
  //console.log('in WorklistEditScreen  , the worklistId is ', worklistId);

  // get the activities from the worklist
  const {
    data: worklist,
    refetch,
    isLoading,
    error,
  } = useGetWorklistDetailsQuery(worklistId);

  const {
    data: aggregateWorkData = [], // Provide a default value
    isLoading: isLoadingWorkData,
    error: errorWorkData,
    refetch: refetchWorkData,
  } = useGetAggregateWorkDataQuery(userid); //selected user, not loged in user

  const {
    data: aggregateWorkDataTreeMap = [],
    isLoading: isLoadingTreeMap,
    error: errorTreeMap,
    refetch: refetchTreeMap,
  } = useGetAggregateWorkDataActivityQuery(userid); //selected user, not loged in user

  const dispatch = useDispatch();

  const [createActivity, { isLoading: loadingCreate }] =
    useCreateActivityMutation(worklistId);

  const [approveAllActivity, { isLoading: loadingApproveAll }] =
    useApproveAllActivityMutation(worklistId);

  const createActivityHandler = async () => {
    if (
      window.confirm(
        `Are you sure you want to create a new activity? for ${worklistId}`
      )
    ) {
      try {
        await createActivity(worklistId);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const approveAllItems = async () => {
    try {
      await approveAllActivity(worklistId);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //
  const [deleteWorklistActivity] = useDeleteWorklistActivityMutation();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  // Calculate the overall running total in minutes
  const overallTotalWorkTimeInMinutes = aggregateWorkData.reduce(
    (total, item) => {
      const hours = Math.floor(item.theWorkTime);
      const minutes = (item.theWorkTime - hours) * 60;
      return total + hours * 60 + minutes;
    },
    0
  );

  // Convert the total minutes to hours and minutes
  const overallTotalWorkHours = Math.floor(overallTotalWorkTimeInMinutes / 60);
  const overallTotalWorkMinutes = overallTotalWorkTimeInMinutes % 60;

  useEffect(() => {
    if (worklist) {
      refetch();

      const formattedRows = worklist.worklistItems
        .filter((item) => item.isActive) // Filter out inactive items
        .slice()
        .sort((a, b) => {
          // Compare by day
          const dayComparison = a.day.localeCompare(b.day);

          if (dayComparison !== 0) {
            return dayComparison;
          }
          /*
          // Compare by period
          const periodComparison = a.period.localeCompare(b.period);

          if (periodComparison !== 0) {
            return periodComparison;
          }
            */

          // Create Date objects for start times
          const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
          const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

          // Compare by start time
          const startTimeComparison = startTimeA - startTimeB;

          if (startTimeComparison !== 0) {
            return startTimeComparison;
          }

          // If start times are the same, compare by activity
          return a.activity.localeCompare(b.activity);
        })
        .map((item) => ({
          id: item._id.toString(), // Ensure each item has a unique ID and convert ObjectId to string
          user: worklist.user.name, // Access the nested user object
          worklistName: worklist.worklistName,
          UKey: item.UKey,
          activity: item.activity,
          activityTotal: item.activityTotal,
          clinicalSite: item.clinicalSite,
          period: item.period,

          startHour: item.startHour,
          startMin: item.startMin,
          endHour: item.endHour,
          endMin: item.endMin,
          day: item.day.slice(2), // "Monday"
          totalTime: item.totalTime,
          comment: item.comment,
          isApproved: item.isApproved,
        }));

      setLoading(false);

      setRows(formattedRows);
      //get window size
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [worklist]);

  const navigate = useNavigate();

  const handleEdit = (id, UKey, userid) => {
    //console.log(`Edit item with ID: ${id}`);
    //console.log(`Edit item with ID: ${uKey}`);

    navigate(`/manager/worklist/${id}/activity/${UKey}/${userid}`);
  };

  const handleTreeMapShow2 = (data) => {
    const theheading = `- Work Time: ${overallTotalWorkHours} hours  ${overallTotalWorkMinutes} minutes`;
    if (!isLoadingTreeMap && !errorTreeMap) {
      navigate(`/worklist/TreeMap`, {
        state: { data, worklistId, theheading, userid, theName },
      });
    } else {
      console.error('Data is not ready or an error occurred.');
    }
  };

  const handleDelete = async (e, worklistId, uKey) => {
    e.preventDefault();

    try {
      //console.log('worklistId', worklistId);
      //console.log('uKey ', uKey);

      await deleteWorklistActivity({ worklistId, uKey }).unwrap();

      toast.success('Item Deleted');
      refetch();
    } catch (err) {
      toast.error(err?.message || 'Failed to delete item');
    }
  };

  const columns = [
    //{ field: 'id', headerName: 'ID', hide: true, width: 90 },
    {
      field: 'user',
      headerName: 'User',
      width: 150,
    },
    // {field: 'worklistName', headerName: 'Worklist Name', width: 150,hide: true },

    // { field: 'UKey', headerName: 'UKey', width: 150, hide: true },
    { field: 'clinicalSite', headerName: 'Clinical Site', width: 150 },
    { field: 'activity', headerName: 'Activity', width: 200 },
    { field: 'day', headerName: 'Day', width: 100 },
    { field: 'period', headerName: 'Period', width: 100 },

    {
      field: 'startTime',
      headerName: 'Start Time',
      width: 100,
      valueGetter: (params) =>
        `${params.row.startHour}:${params.row.startMin
          .toString()
          .padStart(2, '0')}`,
    },

    {
      field: 'endTime',
      headerName: 'End Time',
      width: 100,
      valueGetter: (params) =>
        `${params.row.endHour}:${params.row.endMin
          .toString()
          .padStart(2, '0')}`,
    },

    //{ field: 'startHour', headerName: 'Start Hour', width: 100 },
    //{ field: 'startMin', headerName: 'Start Minute', width: 100 },

    //{ field: 'endHour', headerName: 'End Hour', width: 100 },
    //{ field: 'endMin', headerName: 'End Minute', width: 100 },

    //{field: 'activityTotal', headerName: 'Total Activity', width: 150,},

    //{ field: 'totalTime', headerName: 'Total Time', width: 100 },
    //{ field: 'comment', headerName: 'Comment', width: 200 },
    //{field: 'isApproved',headerName: 'Approved', width: 100, type: 'boolean', },

    {
      field: 'isApproved',
      headerName: 'Approved',
      width: 100,
      renderCell: (params) =>
        params.value ? (
          <FaCheck style={{ color: 'green' }} />
        ) : (
          <FaTimes style={{ color: 'red' }} />
        ),
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            size='small'
            variant='outlined'
            color='info'
            //onClick={() => handleEdit(params.id, params.row.UKey))}
            onClick={() => handleEdit(worklist._id, params.row.UKey, userid)}
          >
            <FaEdit /> Edit
          </Button>
          <Button
            size='small'
            variant='contained'
            color='warning'
            //onClick={() => handleDelete(params.id)}
            onClick={(e) => handleDelete(e, worklist._id, params.row.UKey)}
          >
            <FaTrash style={{ color: 'brown' }} /> Delete
          </Button>
        </Box>
      ),
    },
  ];

  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      //console.log(action);
      console.dir(state);
    },
  };

  //valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,

  /* // Otherwise filter will be applied on fields such as the hidden column id
  const columns = React.useMemo(
    () => data.columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns],
  ); */

  //<MUIDataTable title={'ACME Employee list'} data={data} columns={columns} options={options} />

  //options={options}

  //
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 1,
      bottom: params.isLastVisible ? 0 : 1,
    };
  }, []);
  //
  //
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  //
  //

  const StyledDataGrid = styled(DataGrid)`
    & .${gridClasses.row}.lightGreenRow {
      background-color: ${lightGreen[100]};
    }
    & .${gridClasses.row}.yellowRow {
      background-color: ${yellow[100]};
    }
    & .${gridClasses.row}.redRow {
      background-color: ${red[100]};
    }

    & .${gridClasses.row}.orangeRow {
      background-color: ${orange[100]};
    }

    &
      .${gridClasses.row}:nth-child(odd):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${grey[200]};
    }

    &
      .${gridClasses.row}:nth-child(even):not(.yellowRow):not(.orangeRow):not(
        .lightGreenRow
      ):not(.redRow) {
      background-color: ${common.white}; // Assuming 'common.white' was intended
    }
  `;

  //
  // Go Back - the windowWidth is {windowWidth}

  {
    /*<Link to='/manager/approveWorklist' className='btn btn-light mb-4'>
        Go Back
      </Link>
      */
  }

  return (
    <>
      <Button variant='outline-secondary' onClick={() => navigate(-1)}>
        Go Back
      </Button>

      <Row>
        <Row>
          <Col>
            <h2>Activities</h2>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <MyProfile userid={userid} />
          </Col>

          <Col md={4}></Col>

          <Col md={4} className='text-end'>
            <MyClinicalSCommitments userid={userid} worklistId={worklistId} />
          </Col>
        </Row>

        <Row className='justify-content-between'>
          {/* Distributes content between left and right */}
          <Col xs='auto'>
            {/* Adjust to avoid column taking full width ###### show calculations below ###  //calculations  */}
            <ModalScreen worklistId={worklistId} />
            <WorkVolumeChart2 userId={userid} />
            <WorkVolumeChartTreeMap userId={userid} />
            {/*<WorkVolumeChart />  */}
            <Button
              size='sm'
              style={{
                backgroundColor: 'white',
                color: 'blue',
                border: '2px solid blue',
              }}
              onClick={() => handleTreeMapShow2(aggregateWorkDataTreeMap)} // Wrapped in anonymous function
            >
              {' '}
              <FaLayerGroup /> Activity Full Screen{' '}
            </Button>
          </Col>
          <Col xs='auto'>
            {/* Ensures button is aligned to the right   onClick={() => alert('Button Clicked!')}  */}
            {/*<Button size="sm" variant="primary" onClick={createActivityHandler}> <FaPlus />Activity</Button>*/}

            <Button size='sm' variant='primary' onClick={approveAllItems}>
              <FaPlus />
              Approve All Items
            </Button>
          </Col>
        </Row>

        {windowWidth < 900 ? (
          isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            <div>
              {worklist.worklistItems
                .filter((item) => item.isActive) // Filter out inactive items
                .slice()
                .sort((a, b) => {
                  // Compare by day
                  const dayComparison = a.day.localeCompare(b.day);

                  if (dayComparison !== 0) {
                    return dayComparison;
                  }

                  // Create Date objects for start times
                  const startTimeA = new Date(0, 0, 0, a.startHour, a.startMin);
                  const startTimeB = new Date(0, 0, 0, b.startHour, b.startMin);

                  // Compare by start time
                  const startTimeComparison = startTimeA - startTimeB;

                  if (startTimeComparison !== 0) {
                    return startTimeComparison;
                  }

                  // If start times are the same, compare by activity
                  return a.activity.localeCompare(b.activity);
                })
                .map((item) => (
                  <Card
                    key={item.UKey}
                    className={`mb-3 ${
                      item.activity.startsWith('z') ||
                      item.day.startsWith('0') ||
                      item.period.startsWith('For Noting')
                        ? 'highlightRow'
                        : ''
                    }`}
                  >
                    <Card.Body>
                      <Card.Text>
                        <strong>Clinical Site:</strong> {item.clinicalSite}
                      </Card.Text>
                      <Card.Text>
                        <strong>Activity:</strong> {item.activity}
                      </Card.Text>
                      <Card.Text>
                        <strong>Day:</strong> {item.day.slice(2)}
                      </Card.Text>
                      <Card.Text>
                        <strong>Period:</strong> {item.period}
                      </Card.Text>
                      <Card.Text>
                        <strong>Start Time:</strong> {item.startHour}:
                        {item.startMin}
                      </Card.Text>
                      <Card.Text>
                        <strong>End Time:</strong> {item.endHour}:{item.endMin}
                      </Card.Text>
                      <Card.Text>
                        <strong>Approved:</strong>{' '}
                        {item.isApproved ? (
                          <FaCheck style={{ color: 'green' }} />
                        ) : (
                          <FaTimes style={{ color: 'red' }} />
                        )}
                      </Card.Text>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LinkContainer
                          to={`/manager/worklist/${worklist._id}/activity/${item.UKey}/${userid}`}
                        >
                          <Button
                            size='sm'
                            variant='outline-info'
                            className='btn-sm mx-2'
                          >
                            <FaEdit /> Edit Item
                          </Button>
                        </LinkContainer>
                        <Button
                          size='sm'
                          variant='warning'
                          className='btn-sm'
                          //onClick={handleDelete('', worklist._id, item.UKey)}
                          onClick={(e) =>
                            handleDelete(e, worklist._id, item.UKey)
                          }
                        >
                          <FaTrash
                            className='text-end'
                            style={{ color: 'white' }}
                          />{' '}
                          Delete
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              <Card className='mt-3'>
                <Card.Body>
                  <Card.Text style={{ backgroundColor: 'lightyellow' }}>
                    <strong>Total Activities: </strong>{' '}
                    {calcTotal(worklist.worklistItems)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          )
        ) : (
          <Col md={12} className='d-flex  justify-content-between'>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>
                {error?.data?.message || error.error}
              </Message>
            ) : (
              <Box sx={{ height: 800, width: '100%' }}>
                <StyledDataGrid
                  density='compact'
                  rows={rows}
                  columns={columns}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  loading={loading}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 50,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns name , the other columns will remain visible
                        user: false,
                      },
                    },
                  }}
                  pageSizeOptions={[50]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  getRowClassName={(params) => {
                    if (params.row.activity.startsWith('Lunch Break')) {
                      return 'lightGreenRow';
                    } else if (params.row.period.startsWith('For Noting')) {
                      return 'orangeRow';
                    } else if (params.row.activity.startsWith('zPlease')) {
                      return 'redRow';
                    }
                    return '';
                  }}
                  getRowSpacing={getRowSpacing}
                />
              </Box>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default WorklistEditScreen;
