import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { Button, Dropdown, Modal, Form } from 'react-bootstrap';
import {
  useGetlistAggregateWorkDataSpecialityQuery,
  useUsersWorklistforManagerQuery,
} from '../../slices/worklistsApiSlice.js';
//import ChartJS from 'chart.js/auto';
import { Chart as ChartJS, defaults } from 'chart.js/auto';
import '../../utils/tableStack.css';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
ChartJS.register(annotationPlugin);

function ManagerReportsList() {
  const navigate = useNavigate();
  const [chartInstances, setChartInstances] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState('All');
  const [selectedClinicalSites, setSelectedClinicalSites] = useState([]); // Store selected clinical sites
  const [selectedChart, setSelectedChart] = useState(null); // Full-screen chart data
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const fullScreenChartRef = useRef(null); // Ref for the modal chart
  const { userInfo } = useSelector((state) => state.auth);
  const teamId = userInfo._id;
  const [isChartReady, setIsChartReady] = useState(false);
  const [chartData, setChartData] = useState([]);

  const chartRefs = useRef([]);
  chartRefs.current = Array(100)
    .fill()
    .map((_, i) => chartRefs.current[i] || React.createRef());

  const {
    data: teamAggregateWorkDataTreeMap = [],
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = useGetlistAggregateWorkDataSpecialityQuery(teamId);

  const {
    data: usersWorklist = [],
    isLoadingUW,
    isFetchingUW,
    isSuccessUW,
    errorUW,
  } = useUsersWorklistforManagerQuery(teamId);

  //console.log('teamAggregateWorkDataTreeMap ', teamAggregateWorkDataTreeMap);

  const specialities = [
    ...new Set(teamAggregateWorkDataTreeMap.map((entry) => entry.speciality)),
  ];

  // Extract all clinical sites
  const allClinicalSites = [
    ...new Set(teamAggregateWorkDataTreeMap.map((entry) => entry.clinicalSite)),
  ];

  //__________

  // Helper function to calculate the time diff
  const calculateTimeDifference = (startTime, endTime) => {
    let before9AM = 0;
    let after5PM = 0;

    if (startTime < 9) {
      before9AM = (9 - startTime) * 60; // Convert hours to minutes
    }

    if (endTime > 17) {
      after5PM = (endTime - 17) * 60; // Convert hours to minutes
    }

    const hoursBefore9AM = Math.floor(before9AM / 60);
    const minutesBefore9AM = before9AM % 60;

    const hoursAfter5PM = Math.floor(after5PM / 60);
    const minutesAfter5PM = after5PM % 60;

    return {
      before9AM: `${hoursBefore9AM}h ${minutesBefore9AM}m`,
      after5PM: `${hoursAfter5PM}h ${minutesAfter5PM}m`,
    };
  };

  useEffect(() => {
    if (chartRefs.current.every((ref) => ref.current)) {
      let filteredData =
        selectedSpeciality === 'All'
          ? [...teamAggregateWorkDataTreeMap] // Create a shallow copy
          : teamAggregateWorkDataTreeMap.filter(
              (entry) => entry.speciality === selectedSpeciality
            );

      // Enrich data with worklistId from usersWorklist
      filteredData = filteredData.map((entry) => {
        const matchingWorklist = usersWorklist.find((worklist) => {
          return worklist.user._id === entry.staffid;
        });
        return {
          ...entry,
          worklistId: matchingWorklist ? matchingWorklist.worklistId : null,
        };
      });

      const processedStaffIds = new Set();
      const instances = chartRefs.current
        .map((ref, index) => {
          const staff = filteredData[index];
          if (!staff) return null;
          if (processedStaffIds.has(staff.staffid)) {
            return null;
          }
          processedStaffIds.add(staff.staffid);
          const staffData = filteredData.filter(
            (entry) => entry.staffid === staff.staffid
          );
          const days = [...new Set(staffData.map((entry) => entry.day))];
          const clinicalSites = [
            ...new Set(staffData.map((entry) => entry.clinicalSite)),
          ];
          const filteredClinicalSites = selectedClinicalSites.length
            ? clinicalSites.filter((site) =>
                selectedClinicalSites.includes(site)
              )
            : clinicalSites;
          const uniqueClinicalSites = [
            ...new Set(staffData.map((entry) => entry.clinicalSite)),
          ];
          const filteredStaffData = staffData.filter((entry) =>
            selectedClinicalSites.length
              ? selectedClinicalSites.includes(entry.clinicalSite)
              : true
          );

          // Define totalWorkTimePerDay and labels
          const totalWorkTimePerDay = {};
          const labels = days;
          days.forEach((day) => {
            const dailyEntries = staffData.filter((entry) => entry.day === day);
            const totalWorkTime = dailyEntries.reduce(
              (acc, entry) => acc + entry.theWorkTime,
              0
            );
            totalWorkTimePerDay[day] = { totalWorkTime };
          });

          const datasets = (
            selectedClinicalSites.length
              ? selectedClinicalSites
              : uniqueClinicalSites
          )
            .map((site) => {
              const siteData = filteredStaffData.filter(
                (item) => item.clinicalSite === site
              );
              const totalWorkTimeForSite = siteData.reduce(
                (acc, item) => acc + item.theWorkTime,
                0
              );
              const totalWorkTime = filteredStaffData.reduce(
                (acc, item) => acc + item.theWorkTime,
                0
              );
              const percentage = totalWorkTime
                ? ((totalWorkTimeForSite / totalWorkTime) * 100).toFixed(2)
                : 0;
              // Transform data into an array of [start, end] pairs for floating bars
              const data = days.map((day) => {
                const dayEntries = siteData.filter(
                  (entry) => entry.day === day
                );
                if (dayEntries.length === 0) return null; // No data for this day
                const start = Math.min(
                  ...dayEntries.map((entry) => entry.earliestStartTime)
                );
                const end = Math.max(
                  ...dayEntries.map((entry) => entry.latestEndTime)
                );
                return [start, end]; // Floating bar range
              });
              // Generate initials for the clinical site

              const getInitials = (name) => {
                if (!name || typeof name !== 'string') {
                  return ''; // Return an empty string or fallback value
                }

                // Remove extra spaces and split into words
                const words = name.trim().split(/\s+/); // Uses regex to handle multiple spaces

                if (words.length === 1) return words[0]; // If there's only one word, return it as is

                return (
                  words[0] +
                  ' ' +
                  words
                    .slice(1)
                    .filter((word) => word.length > 0) // Remove any empty strings
                    .map((word) => word[0]?.toUpperCase() || '') // Handle undefined words safely
                    .join(' ')
                );
              };

              const siteInitials = getInitials(site);
              return {
                label:
                  percentage > 0 ? `${siteInitials} (${percentage}%)` : null,
                data: data, // Array of [start, end] pairs
                backgroundColor: `rgba(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, 0.6)`,
                borderColor: `rgba(${Math.floor(
                  Math.random() * 255
                )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                  Math.random() * 255
                )}, 1)`,
                borderWidth: 1,
              };
            })
            .filter((dataset) => dataset.label !== null);

          const totalHours = staffData
            .filter((entry) =>
              selectedClinicalSites.length
                ? selectedClinicalSites.includes(entry.clinicalSite)
                : true
            )
            .reduce((sum, entry) => sum + entry.theWorkTime, 0);

          // Calculate total weekly time differences
          let totalWeeklyBefore9AM = 0;
          let totalWeeklyAfter5PM = 0;

          staffData.forEach((entry) => {
            const timeDiff = calculateTimeDifference(
              entry.earliestStartTime,
              entry.latestEndTime
            );
            if (timeDiff.before9AM !== '0h 0m') {
              totalWeeklyBefore9AM +=
                parseInt(timeDiff.before9AM.split('h ')[0]) * 60 +
                parseInt(timeDiff.before9AM.split('h ')[1].split('m')[0]);
            }
            if (timeDiff.after5PM !== '0h 0m') {
              totalWeeklyAfter5PM +=
                parseInt(timeDiff.after5PM.split('h ')[0]) * 60 +
                parseInt(timeDiff.after5PM.split('h ')[1].split('m')[0]);
            }
          });

          const weeklyHoursBefore9AM = Math.floor(totalWeeklyBefore9AM / 60);
          const weeklyMinutesBefore9AM = totalWeeklyBefore9AM % 60;

          const weeklyHoursAfter5PM = Math.floor(totalWeeklyAfter5PM / 60);
          const weeklyMinutesAfter5PM = totalWeeklyAfter5PM % 60;

          const chartTitle = `${staff.name} - ${
            staff.speciality
          } (Total Hours: ${totalHours.toFixed(
            2
          )}) - Weekly Before 9AM: ${weeklyHoursBefore9AM}h ${weeklyMinutesBefore9AM}m, After 5PM: ${weeklyHoursAfter5PM}h ${weeklyMinutesAfter5PM}m`;

          const chartData = {
            labels: days,
            datasets,
          };

          return new ChartJS(ref.current, {
            type: 'bar',
            data: chartData,
            options: {
              plugins: {
                title: {
                  display: true,
                  text: chartTitle,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const { dataset, dataIndex } = context;
                      const label = dataset.label
                        ? dataset.label.split('(')[0].trim()
                        : 'Unknown Label';
                      const [start, end] = dataset.data[dataIndex]; // Floating bar range
                      const day = labels[dataIndex];
                      const timeDiff = calculateTimeDifference(start, end);
                      return `${label}: ${
                        end - start
                      } hours (Start: ${start}, End: ${end})\nBefore 9AM: ${
                        timeDiff.before9AM
                      }, After 5PM: ${timeDiff.after5PM}`;
                    },
                  },
                },
                labels: {
                  display: true,
                  formatter: (value, context) => {
                    const { dataset, dataIndex } = context;
                    const percentage = (
                      (value /
                        totalWorkTimePerDay[labels[dataIndex]].totalWorkTime) *
                      100
                    ).toFixed(2);
                    return `${value} hours (${percentage}%)`;
                  },
                  color: 'black',
                  font: {
                    size: 12,
                  },
                  align: 'center',
                  anchor: 'center',
                },
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      yMin: 9,
                      yMax: 9,
                      borderColor: 'red',
                      borderWidth: 2,
                      label: {
                        content: '9 hrs',
                        enabled: true,
                        position: 'end',
                      },
                    },
                    line2: {
                      type: 'line',
                      yMin: 17,
                      yMax: 17,
                      borderColor: 'blue',
                      borderWidth: 2,
                      label: {
                        content: '17 hrs',
                        enabled: true,
                        position: 'end',
                      },
                    },
                  },
                },
              },
              onClick: () => {
                setSelectedChart({ staff, chartData, title: chartTitle });
                setShowModal(true);
              },
              scales: {
                x: { stacked: true },
                y: {
                  stacked: false,
                  min: 0,
                  max: 24,
                  ticks: {
                    stepSize: 3,
                    maxTicksLimit: 25,
                    autoSkip: false,
                    callback: (value) => `${value} hrs`,
                  },
                },
              },
            },
          });
        })
        .filter((instance) => instance !== null);
      setChartInstances(instances);
      return () => instances.forEach((instance) => instance.destroy());
    }
  }, [
    teamAggregateWorkDataTreeMap,
    selectedSpeciality,
    selectedClinicalSites,
    usersWorklist,
  ]);
  //___________________________________________________________

  //___________________________________________________________________
  useEffect(() => {
    if (showModal && fullScreenChartRef.current && selectedChart) {
      const { chartData, staff } = selectedChart;

      // Destroy any existing chart instance to avoid duplication
      if (ChartJS.instances.length) {
        ChartJS.instances.forEach((instance) => instance.destroy());
      }

      // Ensure the chartData is formatted for floating bars and handle null/undefined values
      const formattedChartData = {
        labels: chartData.labels,
        datasets: chartData.datasets.map((dataset) => ({
          ...dataset,
          data: dataset.data.map((range) => {
            // Replace null or undefined ranges with [0, 0]
            if (!range || !Array.isArray(range)) {
              return [0, 0];
            }
            return range;
          }),
        })),
      };

      new ChartJS(fullScreenChartRef.current, {
        type: 'bar',
        data: formattedChartData,
        options: {
          plugins: {
            title: {
              display: true,
              text: `${staff.name} - ${staff.speciality}`,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const [start, end] = context.raw; // Use the [start, end] pair from the data
                  return `Work Time: ${
                    end - start
                  } hours (Start: ${start}, End: ${end})`;
                },
              },
            },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  yMin: 9,
                  yMax: 9,
                  borderColor: 'red',
                  borderWidth: 2,
                  label: {
                    content: '9 hrs',
                    enabled: true,
                    position: 'end',
                  },
                },
                line2: {
                  type: 'line',
                  yMin: 17,
                  yMax: 17,
                  borderColor: 'blue',
                  borderWidth: 2,
                  label: {
                    content: '17 hrs',
                    enabled: true,
                    position: 'end',
                  },
                },
              },
            },
          },
          scales: {
            x: { stacked: true },
            y: {
              stacked: false,
              min: 0,
              max: 24,
              ticks: {
                stepSize: 2,
                maxTicksLimit: 25,
                autoSkip: false,
                callback: (value) => `${value} hrs`,
              },
            },
          },
        },
      });
    }
  }, [showModal, selectedChart]);

  const handleSpecialityChange = (speciality) => {
    setSelectedSpeciality(speciality);
  };

  const handleClinicalSiteChange = (e) => {
    const { value, checked } = e.target;

    setSelectedClinicalSites((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((site) => site !== value);
      }
    });
  };

  const handleSelectAllSites = () => {
    if (selectedClinicalSites.length === allClinicalSites.length) {
      setSelectedClinicalSites([]); // Deselect all
    } else {
      setSelectedClinicalSites(allClinicalSites); // Select all
    }
  };

  /*
  const handlePrint = () => {
    if (chartRefs.current.length) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title>Charts</title></head><body>'
      );

      chartRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const dataUrl = ref.current.toDataURL();
          printWindow.document.write(`<div style="margin-bottom: 20px;">
            <img src="${dataUrl}" style="width: 100%; max-width: 600px;" />
            <p>Chart ${index + 1}</p>
          </div>`);
        }
      });

      printWindow.document.write(
        '<script>window.onload = function() { window.print(); window.close(); }</script>'
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    } else {
      alert('No charts available to print.');
    }
  };

  */

  const handlePrint = () => {
    if (chartRefs.current.length) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Charts</title>
            <style>
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                  font-family: Arial, sans-serif;
                }
                .chart-container {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                  gap: 20px;
                  padding: 20px; /* Padding for better aesthetics */
                  background-color: #f0f0f0; /* Light grey background */
                  border-radius: 10px; /* Rounded corners for the container */
                }
                .chart {
                  background-color: #ffffff; /* White background for each chart */
                  border: 2px solid #ffffff; /* White border around each chart */
                  border-radius: 8px; /* Rounded corners for each chart */
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
                  padding: 10px; /* Padding inside the chart container */
                  page-break-inside: avoid; /* Prevent page break inside a chart */
                }
                .chart img {
                  width: 100%; /* Scale the image to fit within the container */
                  height: auto;
                }
                .chart p {
                  text-align: center;
                  margin-top: 5px;
                }
              }
              /* On-screen styles */
              .chart-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr); /* Grid layout for 3 columns */
                gap: 20px;
                padding: 20px;
                background-color: #f0f0f0;
                border-radius: 10px;
              }
              .chart {
                background-color: #ffffff;
                border: 2px solid #ffffff;
                border-radius: 8px;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                padding: 10px;
              }
              .chart img {
                width: 100%;
                height: auto;
              }
            </style>
          </head>
          <body>
            <div class="chart-container">
      `);

      chartRefs.current.forEach((ref, index) => {
        if (ref.current) {
          const dataUrl = ref.current.toDataURL();
          printWindow.document.write(`
            <div class="chart">
              <img src="${dataUrl}" />
              <p>Chart ${index + 1}</p>
            </div>
          `);
        }
      });

      printWindow.document.write(`
            </div>
            <script>
              window.onload = function() {
                window.print();
                window.close();
              };
            </script>
          </body>
        </html>
      `);

      printWindow.document.close();
    } else {
      alert('No charts available to print.');
    }
  };

  //___________________________________________________________________________________________________________

  if (isLoading || isFetching || isLoadingUW || isFetchingUW) {
    return (
      <p>
        <h1>Loading......</h1>
      </p>
    );
  }

  if (!teamAggregateWorkDataTreeMap.length) {
    return <p>No data available.</p>;
  }

  return (
    <>
      <Button variant='outline-secondary' onClick={() => navigate(-1)}>
        Go Back
      </Button>
      <h2>{userInfo.directorateName} Speciality Charts</h2>

      <div
        style={{
          display: 'flex',
          gap: '10px', // Space between buttons
          marginBottom: '20px', // Space below the button group
          justifyContent: 'flex-start', // Align buttons to the left
        }}
      >
        {/* Speciality Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant='primary' id='speciality-dropdown'>
            {selectedSpeciality === 'All'
              ? 'All Specialities'
              : selectedSpeciality}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleSpecialityChange('All')}>
              All Specialities
            </Dropdown.Item>
            {specialities.map((speciality) => (
              <Dropdown.Item
                key={speciality}
                onClick={() => handleSpecialityChange(speciality)}
              >
                {speciality}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Clinical Sites Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant='primary' id='clinical-sites-dropdown'>
            {selectedClinicalSites.length
              ? `${selectedClinicalSites.length} Site(s) Selected`
              : 'Select Clinical Sites'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleSelectAllSites}>
              {selectedClinicalSites.length === allClinicalSites.length
                ? 'Deselect All'
                : 'Select All'}
            </Dropdown.Item>
            {allClinicalSites.map((site) => (
              <Form.Check
                key={site}
                type='checkbox'
                label={site}
                value={site}
                checked={selectedClinicalSites.includes(site)}
                onChange={handleClinicalSiteChange}
                custom
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {/* Print Button */}
        <Button variant='primary' size='sm' onClick={handlePrint}>
          Print Chart
        </Button>
      </div>

      {/* Chart container with responsive grid */}
      <div
        className='chart-container'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', // Default for large screens
          gap: '20px',
          padding: '20px', // Add padding for better aesthetics
          backgroundColor: '#f0f0f0', // Light grey background
          borderRadius: '10px', // Rounded corners for the container
        }}
      >
        {chartRefs.current.map((ref, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#ffffff', // White background for individual charts
              border: '2px solid #ffffff', // White border around each chart
              borderRadius: '8px', // Rounded corners for each chart
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better aesthetics
              padding: '10px', // Padding inside the chart container
            }}
          >
            <canvas ref={ref} />
          </div>
        ))}
      </div>

      {/* Modal for Full-Screen Chart */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='lg'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedChart?.title || 'Chart Details'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedChart && (
            <NavLink
              to={{
                pathname: `/manager/worklist/${selectedChart.staff.worklistId}/edit/${selectedChart?.staff.staffid}`,
                state: { theName: selectedChart.staff.name, worlkistId: '1' },
              }}
              style={{ display: 'block', textDecoration: 'none' }}
            >
              <canvas
                ref={fullScreenChartRef}
                style={{ cursor: 'pointer' }}
              ></canvas>
            </NavLink>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManagerReportsList;
