import { Chart as ChartJS, defaults } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Bar } from 'react-chartjs-2';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState, useRef } from 'react';
import { FaCalendarWeek } from 'react-icons/fa';

import Message from './Message.jsx';
import Loader from './Loader.jsx';

import { useGetAggregateWorkDataQuery } from '../slices/worklistsApiSlice.js';

import '../utils/tableStack.css';

ChartJS.register(annotationPlugin);

function WorkVolumeChart({ userId }) {
  //console.log('WorkVolumeChart : userId', userId);
  const [show, setShow] = useState(false);
  const chartRef = useRef(null);

  const {
    data: aggregateWorkData = [], // Provide a default value
    isLoading,
    error,
    refetch,
  } = useGetAggregateWorkDataQuery(userId);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // Clean up the chart instance before creating a new one
    return () => {
      if (window.Chart) {
        Object.keys(window.Chart.instances).forEach((key) => {
          window.Chart.instances[key].destroy();
        });
      }
    };
  }, [aggregateWorkData]);

  // Group data by day, including start and end times

  const groupedData = aggregateWorkData.reduce((acc, curr) => {
    const {
      day,
      clinicalSite,
      earliestStartTime,
      latestEndTime,
      totalWorkTime,
    } = curr;

    if (!acc[day]) {
      acc[day] = { workTimes: [] };
    }

    acc[day].workTimes.push({
      clinicalSite,
      earliestStartTime,
      latestEndTime,
      totalWorkTime,
    });

    return acc;
  }, {});

  const totalWorkTimePerDay = Object.keys(groupedData).reduce((acc, day) => {
    const dayData = groupedData[day];
    const workTimes = dayData.workTimes;

    // Calculate total work time for each day by summing all totalWorkTime values
    const totalWorkTime = workTimes.reduce(
      (sum, { totalWorkTime }) => sum + totalWorkTime,
      0
    );

    // Find the earliest start time and latest end time for each day
    const earliestStartTime = Math.min(
      ...workTimes.map(({ earliestStartTime }) => earliestStartTime)
    );
    const latestEndTime = Math.max(
      ...workTimes.map(({ latestEndTime }) => latestEndTime)
    );

    acc[day] = {
      totalWorkTime,
      earliestStartTime,
      latestEndTime,
    };
    return acc;
  }, {});

  //console.log('Total Work Time Per Day:', totalWorkTimePerDay);

  //console.log('groupedData fron Bar chart*************', groupedData);

  // Find the highest daily hours for the week
  const highestDailyHours = Math.max(...Object.values(totalWorkTimePerDay));

  // Extract labels and datasets
  const labels = Object.keys(groupedData);
  const clinicalSites = [
    ...new Set(aggregateWorkData.map((item) => item.clinicalSite)),
  ];

  //console.log('clinicalSites fron Bar chart*************', clinicalSites);

  const datasets = clinicalSites.map((site) => ({
    label: site,
    data: labels.map((day) => {
      const dayData = groupedData[day] || { workTimes: [] };
      const workTimeForSite = dayData.workTimes.find(
        (work) => work.clinicalSite === site
      );

      return {
        x: day,
        y: workTimeForSite
          ? [workTimeForSite.earliestStartTime, workTimeForSite.latestEndTime]
          : [null, null],
      };
    }),
    backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${Math.floor(Math.random() * 255)}, 0.6)`,
  }));

  //console.log('datasets fron Bar chart*************', datasets);

  // Calculate the overall running total in minutes
  const overallTotalWorkTimeInMinutes = Object.values(groupedData).reduce(
    (total, dayData) => {
      return dayData.workTimes.reduce((dayTotal, { totalWorkTime }) => {
        const hours = Math.floor(totalWorkTime);
        const minutes = (totalWorkTime - hours) * 60;
        return dayTotal + hours * 60 + minutes;
      }, total);
    },
    0
  );

  //console.log('overallTotalWorkTimeInMinutes ', overallTotalWorkTimeInMinutes);

  // Convert the total minutes to hours and minutes
  const overallTotalWorkHours = Math.floor(overallTotalWorkTimeInMinutes / 60);
  const overallTotalWorkMinutes = overallTotalWorkTimeInMinutes % 60;

  //console.log('overallTotalWorkHours ', overallTotalWorkHours);
  //console.log('overallTotalWorkMinutes 2', overallTotalWorkMinutes);

  // Format the data for the Bar component
  const chartData = {
    labels,
    datasets,
  };

  //console.log('chart by day chartData', chartData);

  // Function to handle print action
  const handlePrint = () => {
    const chart = chartRef.current;
    if (chart) {
      const canvas = chart.canvas;
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title>Weekly Chart</title></head><body>'
      );
      printWindow.document.write(
        `<img src="${dataUrl}" onload="window.print();window.close()" />`
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    }
  };

  //console.log('aggregateWorkData  is', aggregateWorkData);

  return (
    <>
      {isLoading && <Loader />}
      {isLoading ? (
        <>
          <Loader />
          <Loader /> {/* Second loader */}
        </>
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Button variant='outline-info' size='sm' onClick={handleShow}>
            <FaCalendarWeek /> Weekly Chart
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h6>
                  Weekly Chart - Work Time: {overallTotalWorkHours} hours{' '}
                  {overallTotalWorkMinutes} minutes
                </h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {console.log('aggregateWorkData', aggregateWorkData)}

              {aggregateWorkData.length > 0 ? (
                <>
                  <Bar
                    ref={chartRef}
                    data={chartData}
                    options={{
                      scales: {
                        x: { stacked: true },
                        y: {
                          stacked: false,
                          min: 0,
                          max: 24,
                          ticks: {
                            stepSize: 2, // Increment by 1 hour
                            maxTicksLimit: 25, // Ensures a tick for each hour
                            autoSkip: false, // Prevents skipping of any ticks
                            callback: (value) => `${value} hrs`, // Display 'hrs' next to each tick
                          },
                        },
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const { dataset, dataIndex } = context;
                              const dataPoint = dataset.data[dataIndex];

                              // Ensure dataset.label exists before trying to split
                              const label = dataset.label
                                ? dataset.label.split('(')[0].trim()
                                : 'Unknown Label';

                              if (!dataPoint || !dataPoint.y) {
                                return `${label}: Data not available`;
                              }

                              const start = dataPoint.y[0].toFixed(2); // Start time
                              const end = dataPoint.y[1].toFixed(2); // End time
                              const workTime = (end - start).toFixed(2); // Work duration
                              const totalWorkTime =
                                totalWorkTimePerDay[labels[dataIndex]]
                                  ?.totalWorkTime || 0;
                              const percentage = totalWorkTime
                                ? ((workTime / totalWorkTime) * 100).toFixed(2)
                                : '0.00';

                              return `${label}: ${workTime} hours (Start: ${start} hrs, End: ${end} hrs, ${percentage}%)`;
                            },
                          },
                        },
                        labels: {
                          display: true,
                          formatter: (value, context) => {
                            const { dataset, dataIndex } = context;
                            const percentage = (
                              (value /
                                totalWorkTimePerDay[labels[dataIndex]]
                                  .totalWorkTime) *
                              100
                            ).toFixed(2);
                            return `${value} hours (${percentage}%)`;
                          },
                          color: 'black',
                          font: {
                            size: 12,
                          },
                          align: 'center',
                          anchor: 'center',
                        },

                        annotation: {
                          annotations: {
                            line1: {
                              type: 'line',
                              yMin: 9,
                              yMax: 9,
                              borderColor: 'red',
                              borderWidth: 2,
                              label: {
                                content: '9 hrs',
                                enabled: true,
                                position: 'end',
                              },
                            },
                            line2: {
                              type: 'line',
                              yMin: 17,
                              yMax: 17,
                              borderColor: 'blue',
                              borderWidth: 2,
                              label: {
                                content: '17 hrs',
                                enabled: true,
                                position: 'end',
                              },
                            },
                          },
                        },
                      },
                    }}
                    plugins={[
                      {
                        id: 'customBackgroundColor',
                        beforeDraw: (chart) => {
                          const ctx = chart.ctx;
                          ctx.save();
                          ctx.fillStyle = 'lightgray'; // Set the background color
                          ctx.fillRect(0, 0, chart.width, chart.height);
                          ctx.restore();
                        },
                      },
                    ]}
                  />
                  <Button
                    variant='outline-primary'
                    size='sm'
                    onClick={handlePrint}
                  >
                    Print Chart
                  </Button>
                </>
              ) : (
                <Message variant='info'>No data available</Message>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default WorkVolumeChart;
