import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader.jsx';
import Message from '../../components/Message.jsx';
import { useGetUsersManagerQuery } from '../../slices/usersApiSlice.js';
import {
  useGetUserPrivilegeAssignmentsQuery,
  useCreatePrivilegeAssignmentMutation,
  useUpdatePrivilegeAssignmentMutation,
  useGetPrivilegesQuery,
  useDeletePrivilegeAssignmentMutation,
} from '../../slices/privilegeApiSlice.js';

import { FaTrash } from 'react-icons/fa';

const UserPrivilegeAssignmentScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedPrivileges, setSelectedPrivileges] = useState({});
  const [userAssignmentsState, setUserAssignmentsState] = useState([]); // Local state to track assignments

  const {
    data: users,
    isLoading: loadingUsers,
    error: errorUsers,
  } = useGetUsersManagerQuery(userInfo._id);

  const {
    data: userAssignments,
    isLoading: loadingAssignments,
    error: errorAssignments,
    refetch: refetchAssignments,
  } = useGetUserPrivilegeAssignmentsQuery(selectedUser?._id, {
    skip: !selectedUser,
  });

  const {
    data: privileges,
    isLoading,
    error,
    refetch,
  } = useGetPrivilegesQuery();

  const [createAssignment] = useCreatePrivilegeAssignmentMutation();
  const [updateAssignment] = useUpdatePrivilegeAssignmentMutation();
  const [deleteAssignment] = useDeletePrivilegeAssignmentMutation();

  // Handle user selection
  /* const handleUserSelection = (user) => {
    setSelectedUser(user);
    setSelectedSpeciality(null);
    setSelectedPrivileges({}); // Clear selected privileges on new user selection
  };
*/

  const handleUserSelection = (user) => {
    // Clear userAssignments and selected states immediately on new user selection
    setSelectedUser(user);
    setSelectedSpeciality(null);
    setSelectedPrivileges({});
    setUserAssignmentsState([]); // Clear the previous user's assignments immediately

    // No need to manually refetch, the query will auto-fetch based on selectedUser
  };

  // Handle speciality selection
  const handleSpecialitySelection = (speciality) => {
    setSelectedSpeciality(speciality);
    setSelectedPrivileges({}); // Clear selected privileges on new speciality selection
  };

  // Handle checkbox toggle for privileges
  /*
  const handleTogglePrivilege = (privilegeItemId) => {
    setSelectedPrivileges((prevState) => ({
      ...prevState,
      [privilegeItemId]: !prevState[privilegeItemId],
    }));
  };
  */

  const handleTogglePrivilege = (
    privilegeItemId,
    privilegeName,
    description,
    userId,
    userName,
    specialityId,
    specialityName
  ) => {
    setSelectedPrivileges((prevState) => {
      const newState = {
        ...prevState,
        [privilegeItemId]: !prevState[privilegeItemId], // Toggle the privilege state
      };

      // Check if the selected privilege is now `true` (turned on)
      if (newState[privilegeItemId]) {
        // Perform actions when the privilege is turned on
        //console.log(`Privilege ${privilegeItemId} is now turned on.`);
        // Add any additional logic here, such as API calls, state updates, etc.

        handleUpdateOnClick(
          privilegeItemId,
          privilegeName,
          description,
          userId,
          userName,
          specialityId,
          specialityName
        );

        //***********************************************************************************************
      }

      return newState; // Update the state
    });
  };

  // Handle update on click
  const handleUpdateOnClick = async (
    privilegeItemId,
    privilegeName,
    description,
    userId,
    userName,
    specialityId,
    specialityName
  ) => {
    //console.log('handleUpdateOnClick :privilegeItemId', privilegeItemId);
    //console.log('handleUpdateOnClick : userId', userId);
    //console.log('handleUpdateOnClick : userName', userName);
    //console.log('handleUpdateOnClick : specialityId', specialityId);
    //console.log('handleUpdateOnClick : selectedUser._id', selectedUser._id);

    try {
      await updateAssignment({
        selectedUserId: selectedUser._id,
        updatedData: {
          privilegeItemId,
          privilegeName,
          description,
          userId,
          userName,
          specialityId,
          specialityName,
          assignedDate: new Date().toISOString(), // Set the date to now
        },
      });

      // Refetch the userAssignments to ensure the updated privileges are displayed
      refetchAssignments();
      toast.success('User privileges updated successfully');
    } catch (error) {
      console.error('Failed to update privilege', error);
      toast.error('Failed to update privileges');
    }
  };

  //______________________________________________________________________________
  // Handle udelete on click
  const handleDeleteOnClick = async (assignmentId) => {
    //console.log('handleDeleteOnClick :assignmentId', assignmentId);

    try {
      await deleteAssignment(assignmentId);

      // Refetch the userAssignments to ensure the updated privileges are displayed
      refetchAssignments();
      toast.success('User privileges removed successfully');
    } catch (error) {
      console.error('Failed to remove privilege', error);
      toast.error('Failed to remove privileges');
    }
  };

  // Synchronize "Already Assigned Privileges" with "Privilege Items"

  useEffect(() => {
    if (selectedUser) {
      // Clear state immediately when a new user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});

      // Trigger the refetch for assignments
      refetchAssignments({ force: true });
    } else {
      // Clear state if no user is selected
      setUserAssignmentsState([]);
      setSelectedPrivileges({});
    }
  }, [selectedUser, refetchAssignments]);

  useEffect(() => {
    // Wait for loading to complete and assignments to be available
    if (!loadingAssignments && !errorAssignments && selectedUser) {
      if (userAssignments && userAssignments.length > 0) {
        const updatedPrivileges = {};
        userAssignments.forEach((assignedPrivilege) => {
          updatedPrivileges[assignedPrivilege._id] = true; // Mark assigned privileges as true
        });
        setSelectedPrivileges(updatedPrivileges);
        setUserAssignmentsState(userAssignments); // Update state with fetched assignments
      } else {
        // Clear state if no assignments exist for the selected user
        setUserAssignmentsState([]);
        setSelectedPrivileges({});
      }
    }
  }, [loadingAssignments, errorAssignments, userAssignments, selectedUser]);

  //_______________________________________________________________________________________________________________

  // Handle form submission to create or update privilege assignment
  const handleSubmit = async () => {
    const selectedPrivilegeIds = Object.keys(selectedPrivileges).filter(
      (key) => selectedPrivileges[key]
    );

    if (selectedPrivilegeIds.length > 0) {
      try {
        await updateAssignment({
          selectedUserId: selectedUser._id,
          updatedData: { privileges: selectedPrivilegeIds },
        });
        toast.success('User privileges updated successfully');
      } catch (error) {
        toast.error('Failed to update privileges');
      }
    } else {
      toast.error('Please select at least one privilege');
    }
  };

  //____________________________________________________________________________
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  //________________________________________________________________________________

  return (
    <>
      <h3>Assigning Privileges</h3>
      <Row>
        <Col md={4}>
          <h3>Select a User</h3>
          {loadingUsers ? (
            <Loader />
          ) : errorUsers ? (
            <Message variant='danger'>
              {errorUsers?.data?.message || errorUsers.error}
            </Message>
          ) : (
            <Form.Control
              as='select'
              onChange={(e) =>
                handleUserSelection(users.find((u) => u._id === e.target.value))
              }
            >
              <option value=''>-- Select a User --</option>
              {users.map((user) => {
                const fixedName = (user.name || 'No Name')
                  .slice(0, 20)
                  .padEnd(20, ' '); // Fallback to 'No Name' if name is undefined
                const fixedSpeciality = (user.speciality || 'No Speciality')
                  .slice(0, 20)
                  .padEnd(20, ' '); // Fallback to 'No Speciality' if speciality is undefined

                return (
                  <option key={user._id} value={user._id}>
                    {`${fixedName} | ${fixedSpeciality}`}
                  </option>
                );
              })}
            </Form.Control>
          )}
        </Col>

        <Col md={4}>
          <h3>Select a Speciality</h3>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
              {error?.data?.message || error.error}
            </Message>
          ) : (
            selectedUser && (
              <Form.Control
                as='select'
                value={selectedSpeciality || ''} // Bind to selectedSpeciality
                onChange={(e) =>
                  handleSpecialitySelection(
                    privileges?.find((p) => p._id === e.target.value)
                  )
                }
              >
                <option value=''>-- Select a Speciality --</option>
                {privileges?.map((privilege) => (
                  <option key={privilege._id} value={privilege._id}>
                    {privilege.specialityName}
                  </option>
                ))}
              </Form.Control>
            )
          )}
        </Col>
      </Row>

      {selectedUser && selectedSpeciality && (
        <Row className='mt-4'>
          <Col md={6}>
            <h3>Privilege Items</h3>
            {selectedSpeciality ? (
              <Card>
                <Card.Body>
                  <h4>{selectedSpeciality.specialityName}</h4>
                  <Form
                    style={{
                      backgroundColor: '#fef9e7',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                  >
                    {selectedSpeciality?.privileges?.map((privilegeItem) => (
                      <Form.Check
                        key={privilegeItem._id}
                        type='checkbox'
                        checked={selectedPrivileges[privilegeItem._id] || false}
                        onChange={() =>
                          handleTogglePrivilege(
                            privilegeItem._id,
                            privilegeItem.privilegeName,
                            privilegeItem.description,
                            selectedUser._id,
                            selectedUser.name,
                            selectedSpeciality._id,
                            selectedSpeciality.specialityName
                          )
                        }
                        label={
                          <div style={{ display: 'flex', gap: '10px' }}>
                            <div
                              style={{
                                borderBottom: '1px solid black',
                                width: '200px',
                                padding: '5px',
                                backgroundColor: '#f0f0f0',
                              }}
                            >
                              {privilegeItem.privilegeName}
                            </div>
                            <div
                              style={{
                                borderBottom: '1px solid black',
                                width: '300px',
                                padding: '5px',
                              }}
                            >
                              {privilegeItem.description}
                            </div>
                          </div>
                        }
                      />
                    ))}
                  </Form>

                  <Button className='mt-3' onClick={handleSubmit}>
                    Update Privileges
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              <Message>Select a speciality to view privileges</Message>
            )}
          </Col>

          <Col md={6}>
            <h3>Already Assigned Privileges</h3>

            {loadingAssignments ? (
              <Loader />
            ) : errorAssignments ? (
              <Message variant='danger'>
                {errorAssignments?.data?.message || errorAssignments.error}
              </Message>
            ) : (
              <Card>
                <Card.Body>
                  {userAssignmentsState?.length > 0 ? (
                    <Form
                      style={{
                        backgroundColor: '#fef9e7',
                        padding: '10px',
                        borderRadius: '5px',
                      }}
                    >
                      {/* Column Headers */}
                      <div
                        style={{
                          display: 'flex',
                          gap: '10px',
                          fontWeight: 'bold',
                        }}
                      >
                        <div
                          style={{
                            borderBottom: '1px solid black',
                            width: '25px', // Adjust width to match checkbox size
                            padding: '1px',
                            backgroundColor: '#ffa833',
                            textAlign: 'center',
                          }}
                        >
                          <FaTrash />
                        </div>
                        <div
                          style={{
                            borderBottom: '1px solid black',
                            width: '95px',
                            padding: '1px',
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          Assigned Date
                        </div>
                        <div
                          style={{
                            borderBottom: '1px solid black',
                            width: '150px',
                            padding: '5px',
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          Speciality Name
                        </div>
                        <div
                          style={{
                            borderBottom: '1px solid black',
                            width: '300px',
                            padding: '5px',
                            // backgroundColor: '#f0f0f0',
                          }}
                        >
                          Privilege Name
                        </div>
                      </div>

                      {/* Mapped Items */}
                      {userAssignmentsState?.map((assignedPrivilege) => (
                        <Form.Check
                          key={assignedPrivilege._id}
                          type='checkbox'
                          checked={
                            selectedPrivileges[assignedPrivilege._id] || false
                          }
                          onChange={() =>
                            handleDeleteOnClick(assignedPrivilege._id)
                          }
                          label={
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '100px',
                                  padding: '5px',
                                  backgroundColor: '#f0f0f0',
                                }}
                              >
                                {formatDate(assignedPrivilege.assignedDate)}
                              </div>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '150px',
                                  padding: '5px',
                                  backgroundColor: '#f0f0f0',
                                }}
                              >
                                {assignedPrivilege.specialityName}
                              </div>
                              <div
                                style={{
                                  borderBottom: '1px solid black',
                                  width: '300px',
                                  padding: '5px',
                                }}
                                title={assignedPrivilege.description}
                              >
                                {assignedPrivilege.privilegeName}
                              </div>
                            </div>
                          }
                        />
                      ))}
                    </Form>
                  ) : (
                    <Message>No privileges assigned yet</Message>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default UserPrivilegeAssignmentScreen;
