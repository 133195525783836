import React, { useState, useEffect } from 'react';
import {
  useGetGroupedInformationQuery,
  useGetManagersQuery,
  useUpdateClinicalDirectorMutation,
} from '../../slices/usersApiSlice.js';
import '../../utils/tableSwapConsultant.css'; // Import the CSS file

const ChangeClinicalDirectorForm = () => {
  const {
    data: groupedInfo,
    isLoading: isLoadingGroupedInfo,
    error: groupedInfoError,
    refetch, // This is crucial for re-fetching the data
  } = useGetGroupedInformationQuery();

  const { data: managers, isLoading: isLoadingManagers } =
    useGetManagersQuery();

  const [updateClinicalDirector, { isLoading: isUpdating }] =
    useUpdateClinicalDirectorMutation();

  // Store selected clinical directors for each speciality
  const [selectedDirectors, setSelectedDirectors] = useState({});
  const [updatingRow, setUpdatingRow] = useState(null); // Track which row is updating

  // Initialize state with existing clinical directors on first load
  useEffect(() => {
    if (groupedInfo) {
      const initialSelection = {};
      groupedInfo.forEach((info) => {
        initialSelection[info.speciality] = {
          _id: info.clinicalDirectorId || '', // Ensure correct ID
          name: info.clinicalDirector || '',
        };
      });
      setSelectedDirectors(initialSelection);
    }
  }, [groupedInfo]);

  if (isLoadingGroupedInfo || isLoadingManagers) return <div>Loading...</div>;
  if (groupedInfoError) return <div>Error loading grouped information</div>;

  // Sort groupedInfo by Clinical Director and then by Speciality
  const sortedGroupedInfo = groupedInfo.slice().sort((a, b) => {
    if (a.clinicalDirector < b.clinicalDirector) return -1;
    if (a.clinicalDirector > b.clinicalDirector) return 1;
    if (a.speciality < b.speciality) return -1; // If the same clinical director, sort by speciality
    if (a.speciality > b.speciality) return 1;
    return 0;
  });

  // Filter out duplicates based on both speciality and clinicalDirector
  const uniqueGroupedInfo = Array.from(
    new Map(
      sortedGroupedInfo.map((item) => [
        `${item.speciality}-${item.clinicalDirector}`, // Combine speciality and clinicalDirector as unique key
        item,
      ])
    ).values()
  );

  const handleUpdate = async (speciality, oldclinicaldirector) => {
    const selectedManager = selectedDirectors[speciality];
    if (!selectedManager || !selectedManager._id) {
      alert('Please select a clinical director');
      return;
    }

    try {
      // Set updatingRow to the current speciality to show "Updating..." for that row
      setUpdatingRow(speciality);

      // Update the clinical director in the backend
      await updateClinicalDirector({
        speciality,
        oldclinicaldirector,
        newClinicalDirectorId: selectedManager._id,
        newClinicalDirector: selectedManager.name,
      }).unwrap();

      // After the update, refetch the data to reflect the changes
      await refetch();

      // Reload the page to reflect changes
      window.location.reload(); // Force the page to reload

      // Update state to persist the selected clinical director
      setSelectedDirectors((prev) => ({
        ...prev,
        [speciality]: selectedManager,
      }));

      alert('Clinical director updated successfully');
    } catch (error) {
      console.error('Failed to update clinical director:', error);
      alert('Failed to update clinical director');
    } finally {
      // Reset updatingRow after the update
      setUpdatingRow(null);
    }
  };

  return (
    <div className='table-container'>
      <h1>Change Clinical Director</h1>
      <table>
        <thead>
          <tr>
            <th>Speciality</th>
            <th>Current Clinical Director</th>
            <th>Number of Staff</th>
            <th>Change To</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {uniqueGroupedInfo.map((info) => {
            return (
              <tr key={info.speciality}>
                <td>{info.speciality}</td>
                <td>{info.clinicalDirector || 'None Assigned'}</td>
                <td>{info.numberOfStaff}</td>
                <td>
                  <select
                    value={selectedDirectors[info.speciality]?._id || ''}
                    onChange={(e) => {
                      const selectedManager = managers.find(
                        (m) => m._id === e.target.value
                      );
                      setSelectedDirectors((prev) => ({
                        ...prev,
                        [info.speciality]: selectedManager || null,
                      }));
                    }}
                    disabled={isUpdating}
                    data-speciality={info.speciality}
                  >
                    <option value=''>Select Clinical Director</option>
                    {managers.map((manager) => (
                      <option key={manager._id} value={manager._id}>
                        {manager.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button
                    onClick={() =>
                      handleUpdate(info.speciality, info.clinicalDirector)
                    }
                    disabled={isUpdating || updatingRow === info.speciality}
                  >
                    {updatingRow === info.speciality ? 'Updating...' : 'Update'}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChangeClinicalDirectorForm;
