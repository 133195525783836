import { Chart as ChartJS } from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useGetTeamAggregateWorkDataActivityQuery } from '../slices/worklistsApiSlice.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import '../utils/tableStack.css';

ChartJS.register(TreemapController, TreemapElement, zoomPlugin);

function TreemapChart2() {
  const navigate = useNavigate();
  const location = useLocation();

  const chartRef = useRef(null); // Canvas DOM element
  const chartInstanceRef = useRef(null); // Chart.js instance
  const { userInfo } = useSelector((state) => state.auth);
  // const myUserId = userInfo._id;
  const teamId = userInfo._id;
  const [chartInstance, setChartInstance] = useState(null);
  const [isChartReady, setIsChartReady] = useState(false);

  const [currentDataset, setCurrentDataset] = useState('activity');
  const [selectedClinicalSites, setSelectedClinicalSites] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);

  //const { data: teamAggregateWorkDataTreeMap = [] } =
  //  useGetTeamAggregateWorkDataActivityQuery(teamId);

  //____________________get data_____________________________________________________
  const {
    data: teamAggregateWorkDataTreeMap,
    worklistId,
    theheading,
    userid,
    theName,
  } = location.state || {};

  const clinicalSites = [
    ...new Set(teamAggregateWorkDataTreeMap.map((item) => item.clinicalSite)),
  ].map((site) => ({ label: site, value: site }));
  const specialities = [
    ...new Set(teamAggregateWorkDataTreeMap.map((item) => item.speciality)),
  ].map((speciality) => ({ label: speciality, value: speciality }));

  let myUserId = '';
  let theNameDisplay = '';

  if (!userid) {
    myUserId = userInfo._id;
    theNameDisplay = userInfo.salutation + userInfo.name;
  } else {
    myUserId = userid;
    theNameDisplay = theName;
  }

  useEffect(() => {
    if (chartRef.current) {
      const data =
        currentDataset === 'activity'
          ? teamAggregateWorkDataTreeMap
          : teamAggregateWorkDataTreeMap;

      const filteredData = data.filter(
        (item) =>
          (selectedClinicalSites.length > 0
            ? selectedClinicalSites.some(
                (site) => site.value === item.clinicalSite
              )
            : true) &&
          (selectedSpecialities.length > 0
            ? selectedSpecialities.some(
                (speciality) => speciality.value === item.speciality
              )
            : true)
      );

      const totalWorkTime = filteredData
        .reduce((acc, item) => acc + item.theWorkTime, 0)
        .toFixed(2);

      const Staff_No = filteredData.reduce(
        (acc, item) => acc + item.userCount,
        0
      );

      // Destroy the old chart instance if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Define a custom plugin to draw the text
      const customTextPlugin = {
        id: 'customTextPlugin',
        afterDatasetsDraw: (chart) => {
          const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
          } = chart;
          ctx.save();
          ctx.font = 'bold 14px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.backgroundColor = 'yellow';

          ctx.fillText(
            `Weekly Work Time: ${totalWorkTime}`,
            left + width / 2,
            top + height / 2
          );

          ctx.restore();
        },
      };

      // Create a new chart instance  `${theheading}
      const chartInstance = new ChartJS(chartRef.current, {
        type: 'treemap',
        data: {
          datasets: [
            {
              label: ` Weekly Activity Chart, for, ${theNameDisplay} - Weekly Hours: ${totalWorkTime}`,
              tree: filteredData,
              key: 'theWorkTime',
              groups: ['clinicalSite', 'speciality', 'activity'],
              borderWidth: 1,
              spacing: 0.5,
              borderColor: 'rgba(0,0,0,0.1)',
              backgroundColor: (ctx) => {
                if (!ctx || !ctx.raw || !ctx.raw._data || !ctx.raw._data.num) {
                  //return 'rgba(0, 123, 255, 0.4)';
                  return 'rgba(0, 123, 255, 0.4)';
                }
                const alpha = (1 + Math.log(ctx.raw._data.num)) / 5;
                return `rgba(0, 128, 0, ${alpha})`;
              },
              //hoverBackgroundColor: 'rgba(0,123,255,0.7)',
              hoverBackgroundColor: 'rgba(143, 227, 241, 0.7)',
              labels: {
                display: true,
                formatter: (ctx) => {
                  if (
                    ctx &&
                    ctx.raw.g &&
                    ctx.raw.v &&
                    ctx.raw._data &&
                    ctx.raw._data.children
                  ) {
                    const totalUserCount = ctx.raw._data.children.reduce(
                      (acc, child) => acc + child.userCount,
                      0
                    );
                    return `${ctx.raw.g} - Hours ${ctx.raw.v} `;
                  }
                  return 'blank'; // Return 'blank' if label or num is undefined
                },
                color: 'black',
                font: {
                  size: 12,
                },
                align: 'center',
                anchor: 'center',
                clip: true,
                overflow: 'fit',
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              text: `${userInfo.directorateName} Team - ${
                currentDataset.charAt(0).toUpperCase() + currentDataset.slice(1)
              } Chart for - ${userInfo.salutation} ${userInfo.name}`,
            },
            legend: {
              display: true,
            },
            customTextPlugin: {}, // Include the custom plugin
            zoom: {
              pan: {
                enabled: true,
                mode: 'xy',
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy',
              },
            },
          },
        },
        plugins: [customTextPlugin], // Register the custom plugin
      });

      // Store the instance in the ref
      chartInstanceRef.current = chartInstance;
      // Indicate that the chart is ready
      setIsChartReady(true);
      // Cleanup on unmount
      return () => {
        chartInstance.destroy();
      };
    }
  }, [
    currentDataset,
    selectedClinicalSites,
    selectedSpecialities,
    teamAggregateWorkDataTreeMap,
    userInfo,
  ]);

  // Function to handle print action
  const handlePrint = () => {
    if (isChartReady && chartRef.current) {
      const canvas = chartRef.current;
      const dataUrl = canvas.toDataURL();
      const printWindow = window.open('', '_blank');
      printWindow.document.write(
        '<html><head><title> Chart by Hours</title></head><body>'
      );
      printWindow.document.write(
        `<img src="${dataUrl}" onload="window.print();window.close()" />`
      );
      printWindow.document.write('</body></html>');
      printWindow.document.close();
    }
  };

  const handleResetZoom = () => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.resetZoom();
    }
  };

  return (
    <div>
      <h3>
        {userInfo.directorateName} -{' '}
        {currentDataset.charAt(0).toUpperCase() + currentDataset.slice(1)} Chart
        for {userInfo.salutation} {userInfo.name}
      </h3>

      <div style={{ display: 'flex', gap: '20px' }}>
        <div>
          <label htmlFor='clinicalSite'>Clinical Site: </label>
          <MultiSelect
            className='multi-select'
            options={[
              ...clinicalSites,

              { label: 'Deselect All', value: 'deselectAll' },
              { label: 'Select All', value: 'selectAll' },
            ]}
            value={selectedClinicalSites}
            onChange={(selected) => {
              if (selected.some((option) => option.value === 'selectAll')) {
                setSelectedClinicalSites(clinicalSites);
              } else if (
                selected.some((option) => option.value === 'deselectAll')
              ) {
                setSelectedClinicalSites([]);
              } else {
                setSelectedClinicalSites(selected);
              }
            }}
            labelledBy='Select Clinical Sites'
          />
        </div>

        <div>
          <label htmlFor='speciality'>Speciality: </label>
          <MultiSelect
            className='multi-select'
            options={[
              ...specialities,
              { label: 'Deselect All', value: 'deselectAll' },
              { label: 'Select All', value: 'selectAll' },
            ]}
            value={selectedSpecialities}
            onChange={(selected) => {
              if (selected.some((option) => option.value === 'selectAll')) {
                setSelectedSpecialities(specialities);
              } else if (
                selected.some((option) => option.value === 'deselectAll')
              ) {
                setSelectedSpecialities([]);
              } else {
                setSelectedSpecialities(selected);
              }
            }}
            labelledBy='Select Specialities'
          />
        </div>
      </div>
      <canvas ref={chartRef} />
      <button onClick={handleResetZoom}>Reset Zoom</button>
      <Button variant='outline-primary' size='sm' onClick={handlePrint}>
        Print Chart
      </Button>
    </div>
  );
}

export default TreemapChart2;
