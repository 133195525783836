import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
  useGetManagersQuery,
} from '../../slices/usersApiSlice';

import { useGetDirectoratesNoPageQuery } from '../../slices/directoratesApiSlice.js';
import { useGetCostcentresNoPageQuery } from '../../slices/costcentresApiSlice.js';

//#f5f5dc
const dropdownStyle = {
  backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
  // Add other styles as needed
};
const textStyle = {
  backgroundColor: '#f5f5dc',
  // Add other styles as needed
};

const UserEditScreen = () => {
  const [availableContractTypes, setAvailableContractTypes] = useState([]);

  const { id: userId } = useParams();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLocum, setIsLocum] = useState(false);
  const [locumEndDate, setLocumEndDate] = useState(new Date());

  const [isApproved, setIsApproved] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const [employeenumber, setEmployeenumber] = useState('');
  const [jobtitle, setJobtitle] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [subspeciality, setSubspeciality] = useState('');
  const [location, setLocation] = useState('');
  const [directoratedepartment, setDirectoratedepartment] = useState('');
  const [primaryemployer, setPrimaryemployer] = useState(false);
  const [yourclinicaldirector, setYourclinicaldirector] = useState('');
  const [contracttype, setContracttype] = useState('');
  const [approvedtrainer, setApprovedtrainer] = useState(false);
  const [areyouaclinicaldirector, setAreyouaclinicaldirector] = useState(false);
  const [yourclinicaldirectorID, setYourclinicaldirectorID] = useState('');
  const [oncallcommitmentYN, setOncallcommitmentYN] = useState(false);
  const [oncallcommitmentcomment, setOncallcommitmentcomment] = useState('');

  const [occupationallyExposedYN, setOccupationallyExposedYN] = useState(false);
  const [occupationallyExposedComment, setOccupationallyExposedComment] =
    useState('--');
  const [ionisingRadiationProceduresYN, setIonisingRadiationProceduresYN] =
    useState(false);
  const [
    ionisingRadiationProceduresComment,
    setIonisingRadiationProceduresComment,
  ] = useState('--');
  const [useOfLasersYN, setUseOfLasersYN] = useState(false);
  const [useOfLasersComment, setUseOfLasersComment] = useState('--');

  const [buckleycontract, setBuckleycontract] = useState('');
  const [comment, setComment] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [reviewDate, setReviewDate] = useState(new Date());
  const [salutation, setSalutation] = useState('--');

  //#f5f5dc
  const dropdownStyle = {
    backgroundColor: '#F5F5F5', //'#F0E68C'                 //'lightyellow',
    // Add other styles as needed
  };
  const textStyle = {
    backgroundColor: '#f5f5dc',
    // Add other styles as needed
  };

  const {
    data: directoratesdata,
    isLoading: isLoadingD,
    error: errorD,
    refetch: refetchD,
  } = useGetDirectoratesNoPageQuery();
  const {
    data: costcentresdata,
    isLoading: isLoadingBC,
    error: errorBC,
    refetch: refetchBC,
  } = useGetCostcentresNoPageQuery();

  const {
    data: managers,
    refetch: refetchM,
    isLoading: isLoadingM,
    errorM,
  } = useGetManagersQuery();

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGetUserDetailsQuery(userId);

  const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateUser({
        userId,
        name,
        // email,
        isAdmin,
        isLocum,
        locumEndDate,
        isApproved,
        isManager,
        employeenumber,
        firstname,
        lastname,
        jobtitle,
        speciality,
        subspeciality,
        location,
        directoratedepartment,
        primaryemployer,
        yourclinicaldirector,
        contracttype,
        approvedtrainer,
        areyouaclinicaldirector,
        oncallcommitmentYN,
        oncallcommitmentcomment,
        occupationallyExposedYN,
        occupationallyExposedComment,
        ionisingRadiationProceduresYN,
        ionisingRadiationProceduresComment,
        useOfLasersYN,
        useOfLasersComment,

        effectiveDate,
        buckleycontract,
        reviewDate,
        comment,
        yourclinicaldirectorID,
        salutation,
      }).unwrap();
      toast.success('user updated successfully');
      refetch();
      navigate('/admin/userlist');
    } catch (err) {
      toast.error(err?.data?.message || err?.error || err?.message);
    }
  };

  // Define the options data
  const contractData = {
    'Public Only Consultant Contract': [
      'POCC Clinician',
      'POCC Academic Professor',
      'POCC Associate Professor',
      'POCC Senior Lecturer',
    ],
    '2008 Contract': [
      'Type A',
      'Type B',
      'Type B Academic',
      'Type B*',
      'Type B* Academic',
      'Type C',
      'Type C Academic',
    ],
    'Buckley Contract': [
      'Category I',
      'Category II',
      'Category II Senior Lecturer',
    ],
  };

  // Handle changes in the first dropdown
  const handleBuckleyContractChange = (e) => {
    const selectedContract = e.target.value;
    setBuckleycontract(selectedContract);
    //console.log('selectedContract', selectedContract);
    //console.log('buckleycontract', buckleycontract);

    // Update the available options for the second dropdown
    setAvailableContractTypes(contractData[selectedContract] || []);
    //setContracttype(''); // Reset the second dropdown value
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setIsAdmin(user.isAdmin);
      setIsLocum(user.isLocum);

      const locumEndDate1 = new Date(user.locumEndDate);
      let locumEndDate2 = '';

      if (
        locumEndDate1 &&
        locumEndDate1 instanceof Date &&
        !isNaN(locumEndDate1.getTime())
      ) {
        locumEndDate2 = locumEndDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        locumEndDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      setLocumEndDate(locumEndDate2);

      setIsApproved(user.isApproved);
      setIsManager(user.isManager);

      setFirstname(user.firstname);
      setLastname(user.lastname);

      setEmployeenumber(user.employeenumber);
      setJobtitle(user.jobtitle);
      setSpeciality(user.speciality);
      setSubspeciality(user.subspeciality);
      setLocation(user.location);
      setDirectoratedepartment(user.directoratedepartment);
      setPrimaryemployer(user.primaryemployer);
      setYourclinicaldirector(user.yourclinicaldirector);
      setContracttype(user.contracttype);
      setApprovedtrainer(user.approvedtrainer);
      setAreyouaclinicaldirector(user.areyouaclinicaldirector);
      setOncallcommitmentYN(user.oncallcommitmentYN);
      setOncallcommitmentcomment(user.oncallcommitmentcomment);

      //_________________________________________________________________
      setOccupationallyExposedYN(user.occupationallyExposedYN);
      setOccupationallyExposedComment(user.occupationallyExposedComment);
      setIonisingRadiationProceduresYN(user.ionisingRadiationProceduresYN);
      setIonisingRadiationProceduresComment(
        user.ionisingRadiationProceduresComment
      );
      setUseOfLasersYN(user.useOfLasersYN);
      setUseOfLasersComment(user.useOfLasersComment);

      //setBuckleycontract(user.buckleycontract);
      if (!buckleycontract) {
        setBuckleycontract(user.buckleycontract);
      }

      // Populate the second dropdown options based on the initial or updated value of buckleycontract

      if (buckleycontract) {
        setAvailableContractTypes(contractData[buckleycontract] || []);
      }
      setComment(user.comment);

      setYourclinicaldirectorID(user.yourclinicaldirectorID);
      setSalutation(user.salutation);

      const reviewDate1 = new Date(user.reviewDate);

      //const reviewDate2 = reviewDate1.toISOString().slice(0, 10);
      //const reviewDate2 = reviewDate1 ? reviewDate1.toISOString().slice(0, 10) : '';
      //const reviewDate2 = (reviewDate1 ?? '').toISOString().slice(0, 10);

      let reviewDate2 = '';

      if (
        reviewDate1 &&
        reviewDate1 instanceof Date &&
        !isNaN(reviewDate1.getTime())
      ) {
        reviewDate2 = reviewDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        reviewDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      //console.log('user.reviewdate ,reviewDate1 , reviewDate2 ', user.reviewdate, " ", reviewDate1, " ", reviewDate2);

      const effectiveDate1 = new Date(user.effectiveDate);
      //const effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
      //const effectiveDate2 = (effectiveDate1 ?? '').toISOString().slice(0, 10);

      let effectiveDate2 = '';

      if (
        effectiveDate1 &&
        effectiveDate1 instanceof Date &&
        !isNaN(effectiveDate1.getTime())
      ) {
        effectiveDate2 = effectiveDate1.toISOString().slice(0, 10);
        // Further processing with reviewDate2
      } else {
        effectiveDate2 = '';
        //console.error('reviewDate1 is not a valid Date object or is null/undefined');
        // Handle the case where reviewDate1 is not a valid date or is null/undefined
      }

      //console.log('user.effectiveDate ,effectiveDate1 , effectiveDate2 ', user.reviewdate, " ", reviewDate1, " ", reviewDate2);

      setReviewDate(reviewDate2);
      setEffectiveDate(effectiveDate2);
    }
  }, [user, buckleycontract]);

  return (
    <>
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <Container fluid>
        <h1>Edit Employee Profile</h1>
        {loadingUpdate && <Loader />}
        {isLoading || isLoadingBC || isLoadingD || isLoadingM ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              {/* First Column */}
              {/* Group Title, First Name, and Last Name on the same line */}
              <Col md={4}>
                <div
                  style={{
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#F0F8FF',
                  }}
                >
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId='salutation'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          as='select'
                          value={salutation}
                          onChange={(e) => setSalutation(e.target.value)}
                          style={textStyle}
                        >
                          <option value=''>Select Title</option>
                          <option value='Cllr.'>Cllr.</option>
                          <option value='Mr.'>Mr.</option>
                          <option value='Ms.'>Ms.</option>
                          <option value='Mrs'>Mrs</option>
                          <option value='Miss'>Miss</option>
                          <option value='Dr.'>Dr.</option>
                          <option value='Professor'>Professor</option>
                          <option value='Mx'>Mx</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <Form.Group controlId='firstname'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter First Name'
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={5}>
                      <Form.Group controlId='lastname'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Last Name'
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId='employeenumber'>
                        <Form.Label>Employee Number</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Employee Number'
                          value={employeenumber}
                          onChange={(e) => setEmployeenumber(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId='jobtitle'>
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Job Title'
                          value={jobtitle}
                          onChange={(e) => setJobtitle(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId='effectiveDate'>
                        <Form.Label>Effective Date</Form.Label>
                        <Form.Control
                          type='date'
                          value={effectiveDate}
                          onChange={(e) => setEffectiveDate(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId='reviewDate'>
                        <Form.Label>Review Date </Form.Label>
                        <Form.Control
                          type='date'
                          placeholder='Review Date '
                          value={reviewDate} // {effectiveDate.toISOString().split('T')[0]}  //{effectiveDate}
                          onChange={(e) => setReviewDate(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>

                <div>
                  <p></p>
                </div>

                <div
                  style={{
                    border: '1px solid #ccc',
                    padding: '16px',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    backgroundColor: '#F0F8FF',
                  }}
                >
                  <Form.Group className='my-2' controlId='isApproved'>
                    <Form.Label>Allow login</Form.Label>
                    <Form.Check
                      type='checkbox'
                      label='Approved to log in'
                      checked={isApproved}
                      onChange={(e) => setIsApproved(e.target.checked)}
                      style={textStyle}
                    ></Form.Check>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <Form.Check
                        type='checkbox'
                        label={
                          isLocum ? 'Locum Staff,EndDate->' : 'Locum Staff'
                        }
                        checked={isLocum}
                        onChange={(e) => setIsLocum(e.target.checked)}
                        style={textStyle}
                      ></Form.Check>

                      {isLocum && ( // Conditionally render the Locum End Date
                        <div>
                          <Form.Control
                            type='date'
                            value={locumEndDate}
                            onChange={(e) => setLocumEndDate(e.target.value)}
                            style={textStyle}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>

                  <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    Approval Levels
                  </div>
                  <Form.Group className='my-2' controlId='isadmin'>
                    <Form.Check
                      type='checkbox'
                      label='Admin'
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                      style={textStyle}
                    ></Form.Check>
                  </Form.Group>
                  <Form.Group className='my-2' controlId='ismanager'>
                    <Form.Check
                      type='checkbox'
                      label='Manager'
                      checked={isManager}
                      onChange={(e) => setIsManager(e.target.checked)}
                      style={textStyle}
                    ></Form.Check>
                    <Form.Group
                      className='my-2'
                      controlId='areyouaclinicaldirector'
                    >
                      <Form.Check
                        type='checkbox'
                        label='Clinical Director'
                        checked={areyouaclinicaldirector}
                        onChange={(e) => {
                          setAreyouaclinicaldirector(e.target.checked);
                          setIsManager(e.target.checked);
                        }}
                        style={textStyle}
                      ></Form.Check>
                    </Form.Group>
                  </Form.Group>
                </div>
              </Col>

              {/* Further sections for additional information */}

              {/* Second Column **************************************************************************************/}
              <Col md={4}>
                <div
                  style={{
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#F0F8FF',
                  }}
                >
                  {/* Add other form groups as needed, structured in a similar way */}
                  <Form.Group className='my-2' controlId='yourclinicaldirector'>
                    <Form.Label>Your Clinical Director</Form.Label>
                    <Form.Control
                      as='select'
                      value={yourclinicaldirector}
                      onChange={(e) => {
                        // Set multiple states
                        setYourclinicaldirector(e.target.value);
                        const selectedIndex = e.target.selectedIndex;
                        const selectedID =
                          e.target.options[selectedIndex].getAttribute(
                            'data-id'
                          );
                        setYourclinicaldirectorID(selectedID);
                      }}
                      style={textStyle}
                    >
                      <option value=''>Select Your Clinical Director</option>
                      {managers.map((manager) => (
                        <option
                          key={manager._id}
                          value={manager.name}
                          data-id={manager._id}
                        >
                          {manager.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId='setDirectorate'>
                        <Form.Label>Directorate</Form.Label>
                        <Form.Control
                          as='select'
                          placeholder='Directorate'
                          value={directoratedepartment}
                          //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle}
                          onChange={(e) =>
                            setDirectoratedepartment(e.target.value)
                          }
                          style={textStyle}
                        >
                          <option value={directoratedepartment}>
                            {directoratedepartment}
                          </option>

                          {directoratesdata &&
                            directoratesdata
                              .slice() // Create a shallow copy of the array to avoid mutating the original array
                              .sort((a, b) =>
                                a.directorate.localeCompare(b.directorate)
                              ) // Sort by activityName
                              .map((directorateList) => (
                                <option
                                  key={directorateList._id}
                                  value={`${directorateList.directorate}`}
                                >
                                  {directorateList.directorate}
                                </option>
                              ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId='location'>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter Location'
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId='CostCentre'>
                        <Form.Label>Speciality</Form.Label>
                        <Form.Control
                          as='select'
                          placeholder='Speciality'
                          value={speciality}
                          //onChange={(e) => setActivityName(e.target.value)} style={dropdownStyle}
                          onChange={(e) => setSpeciality(e.target.value)}
                          // style={{ width: '200px', flexBasis: 'calc(90% - 5px)', marginBottom: '10px' }}
                          style={textStyle}
                        >
                          <option value={speciality}>{speciality}</option>

                          {costcentresdata &&
                            costcentresdata
                              .slice() // Create a shallow copy of the array to avoid mutating the original array
                              .sort((a, b) =>
                                a.speciality.localeCompare(b.speciality)
                              ) // Sort by activityName
                              .map((costcentreList) => (
                                <option
                                  key={costcentreList._id}
                                  value={`${costcentreList.speciality}`}
                                >
                                  {costcentreList.speciality}
                                </option>
                              ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId='subspeciality'>
                        <Form.Label>Sub-Speciality (SI)</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Sub-Speciality'
                          value={subspeciality}
                          onChange={(e) => setSubspeciality(e.target.value)}
                          style={textStyle}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group controlId='buckleycontract'>
                        <Form.Label>Your Contract Group</Form.Label>
                        <Form.Control
                          as='select'
                          value={buckleycontract}
                          onChange={handleBuckleyContractChange}
                        >
                          <option value={buckleycontract}>
                            {buckleycontract}
                          </option>
                          {Object.keys(contractData).map((contract) => (
                            <option key={contract} value={contract}>
                              {contract}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Col sm={6}>
                      <Form.Group controlId='contracttype'>
                        <Form.Label>Contract Type</Form.Label>
                        <Form.Control
                          as='select'
                          value={contracttype}
                          onChange={(e) => setContracttype(e.target.value)}
                          disabled={!buckleycontract} // Disable if no contract group is selected
                        >
                          <option value={contracttype}>{contracttype}</option>
                          {availableContractTypes.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className='my-2' controlId='comment'>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as='textarea' // Set the 'as' prop to 'textarea' for multiline text entry
                      rows={3} // Specify the number of visible text rows
                      placeholder='Comments'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      style={textStyle}
                    />
                  </Form.Group>

                  {/* Add other fields for the second column */}

                  {/* Add other fields for the first column */}
                </div>
              </Col>

              {/* Further sections for additional information */}
              {/* Third Column **************************************************************************************/}

              {/* thirdColumn **************************************************************************************/}

              <Col md={4}>
                <div
                  style={{
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#F0F8FF',
                  }}
                >
                  {/* Add other form groups as needed, structured in a similar way */}
                  Check all that apply
                  <div>
                    <Form.Group className='my-2' controlId='oncallcommitmentYN'>
                      <Form.Check
                        type='checkbox'
                        label='Check box if you have an On Call Commitment'
                        checked={oncallcommitmentYN}
                        onChange={(e) =>
                          setOncallcommitmentYN(e.target.checked)
                        }
                        style={textStyle}
                      />
                    </Form.Group>

                    {oncallcommitmentYN && (
                      <Form.Group
                        className='my-2'
                        controlId='oncallcommitmentcomment'
                      >
                        <Form.Label>Details re: On Call Commitment</Form.Label>
                        <Form.Control
                          as='textarea' // Set the 'as' prop to 'textarea' for multiline text entry
                          rows={1} // Specify the number of visible text rows
                          type='oncallcommitmentcomment'
                          placeholder='Details re: On Call Commitment'
                          value={oncallcommitmentcomment}
                          onChange={(e) =>
                            setOncallcommitmentcomment(e.target.value)
                          }
                          style={textStyle}
                        />
                      </Form.Group>
                    )}
                  </div>
                  <Form.Group className='my-2' controlId='approvedtrainer'>
                    <Form.Check
                      type='checkbox'
                      label='Check box if you are you an approved trainer'
                      checked={approvedtrainer}
                      onChange={(e) => setApprovedtrainer(e.target.checked)}
                      style={textStyle}
                    ></Form.Check>
                  </Form.Group>
                  {/*
<Form.Group className='my-2' controlId='areyouaclinicaldirector'>
  <Form.Check
    type='checkbox'
    label='Check box if Are You A Clinical Director'
    checked={areyouaclinicaldirector}
    onChange={(e) => setAreyouaclinicaldirector(e.target.checked)}
    style={textStyle}

  ></Form.Check>
</Form.Group>

*/}
                  <Form.Group className='my-2' controlId='primaryemployer'>
                    <Form.Check
                      type='checkbox'
                      label='Check box if Beaumont is you primary employer'
                      checked={primaryemployer}
                      onChange={(e) => setPrimaryemployer(e.target.checked)}
                      style={textStyle}
                    ></Form.Check>
                  </Form.Group>
                </div>

                <div>
                  <p></p>
                </div>

                <div>
                  <p></p>
                  <Link
                    to={`../spqualList/${userId}/edit`}
                    className='btn btn-light mb-4'
                  >
                    Enter any Specialised Expertise - Advanced Competencies
                  </Link>
                </div>

                {/* Further sections for additional information */}
                {/* radiation Column **************************************************************************************/}

                <div
                  style={{
                    border: '1px solid #ddd',
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#F0F8FF',
                  }}
                >
                  {/* Add other form groups as needed, structured in a similar way */}
                  Occupational exposure to ionising radiation. Check all that
                  apply
                  <div>
                    <Form.Group
                      className='my-2'
                      controlId='occupationallyExposedYN'
                    >
                      <Form.Check
                        type='checkbox'
                        label='Are you currently employed in another undertaking where you are occupationally exposed to ionising radiation e.g. X-rays'
                        checked={occupationallyExposedYN}
                        onChange={(e) =>
                          setOccupationallyExposedYN(e.target.checked)
                        }
                        style={textStyle}
                      />
                    </Form.Group>

                    {occupationallyExposedYN && (
                      <Form.Group
                        className='my-2'
                        controlId='occupationallyExposedComment'
                      >
                        <Form.Label>
                          Details: please identify the undertaking{' '}
                        </Form.Label>
                        <Form.Control
                          as='textarea' // Set the 'as' prop to 'textarea' for multiline text entry
                          rows={1} // Specify the number of visible text rows
                          type='occupationallyExposedComment'
                          placeholder='Details: please identify the undertaking'
                          value={occupationallyExposedComment}
                          onChange={(e) =>
                            setOccupationallyExposedComment(e.target.value)
                          }
                          style={textStyle}
                        />
                      </Form.Group>
                    )}
                  </div>
                  <div>
                    <Form.Group
                      className='my-2'
                      controlId='ionisingRadiationProceduresYN'
                    >
                      <Form.Check
                        type='checkbox'
                        label='Do you carry out clinical procedures that involve the use of ionising radiation e.g. Fluoroscopy'
                        checked={ionisingRadiationProceduresYN}
                        onChange={(e) =>
                          setIonisingRadiationProceduresYN(e.target.checked)
                        }
                        style={textStyle}
                      />
                    </Form.Group>

                    {ionisingRadiationProceduresYN && (
                      <Form.Group
                        className='my-2'
                        controlId='ionisingRadiationProceduresComment'
                      >
                        <Form.Label>
                          Details: Radiation safety course approved by the Irish
                          Medical Council{' '}
                        </Form.Label>
                        <Form.Control
                          as='textarea' // Set the 'as' prop to 'textarea' for multiline text entry
                          rows={2} // Specify the number of visible text rows
                          type='ionisingRadiationProceduresComment'
                          placeholder='Please submit evidence of having completed, in the last 3 years, a radiation safety course approved by the Irish Medical Council or an equivalent'
                          value={ionisingRadiationProceduresComment}
                          onChange={(e) =>
                            setIonisingRadiationProceduresComment(
                              e.target.value
                            )
                          }
                          style={textStyle}
                        />
                      </Form.Group>
                    )}
                  </div>
                  {/*
<Form.Group className='my-2' controlId='areyouaclinicaldirector'>
  <Form.Check
    type='checkbox'
    label='Check box if Are You A Clinical Director'
    checked={areyouaclinicaldirector}
    onChange={(e) => setAreyouaclinicaldirector(e.target.checked)}
    style={textStyle}

  ></Form.Check>
</Form.Group>

*/}
                  <div>
                    <Form.Group className='my-2' controlId='useOfLasersYN'>
                      <Form.Check
                        type='checkbox'
                        label='Do you carry out clinical procedures that involve the use of LASERS '
                        checked={useOfLasersYN}
                        onChange={(e) => setUseOfLasersYN(e.target.checked)}
                        style={textStyle}
                      />
                    </Form.Group>

                    {useOfLasersYN && (
                      <Form.Group
                        className='my-2'
                        controlId='useOfLasersComment'
                      >
                        <Form.Label>
                          Details: LASER safety training course's{' '}
                        </Form.Label>
                        <Form.Control
                          as='textarea' // Set the 'as' prop to 'textarea' for multiline text entry
                          rows={2} // Specify the number of visible text rows
                          type='useOfLasersComment'
                          placeholder='Please submit evidence of having completed a LASER safety training course'
                          value={useOfLasersComment}
                          onChange={(e) =>
                            setUseOfLasersComment(e.target.value)
                          }
                          style={textStyle}
                        />
                      </Form.Group>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </Container>
    </>
  );
};

export default UserEditScreen;
